<template>
  <div class="register">
    <div id="header">
      <div class="header-content">
        <div class="logo pub-logo fl">
          <router-link :to="{name:'Home'}" title="集元小样电子元器件商城">
            <img
              src="../assets/img/logo-big.png"
              alt="集元小样电子元器件商城"
              style="width: 220px; height: 76px"
            />
          </router-link>
        </div>
        <div class="fr header-contact header-contact-grey call-us">
          <img src="../assets/img/qq.svg" style="width: 21px;height: 21px;">
          <b>在线咨询</b>
        </div>
      </div>
    </div>
    <div id="main">
      <div class="header-line"></div>
      <div class="go-login" id="registerPage">
        <div class="login-info fr">
          已有账号？现在
          <router-link :to="{name:'Login'}" rel="nofollow">登录</router-link>
        </div>
      </div>
      <form onsubmit="return false;" class="reg-form">
        <div class="register-content">
          <div class="register-title">欢迎注册集元小样</div>
          <div class="register-form">
            <div class="step-content step-one">
              <div class="register-input-item clear">
                <div class="register-input-label fl label-require">公司名称/昵称</div>
                <div class="register-input-content fl">
                  <input
                    type="text"
                    class="long-input phone-input reg-input borderRed"
                    id="nameInput"
                    placeholder="请输入公司名称或昵称"
                    autocomplete="on"
                    v-model="company_name"
                  />
                </div>
              </div>
              <div class="register-input-item clear">
                <div class="register-input-label fl label-require">手机号码</div>
                <div class="register-input-content fl">
                  <input
                    type="text"
                    class="long-input phone-input reg-input borderRed"
                    id="phoneInput"
                    placeholder="请输入手机号码"
                    autocomplete="on"
                    v-model="phone"
                  />
                </div>
              </div>
              <div class="register-input-item clear">
                <div class="register-input-label fl label-require" style="line-height: 34px;">密码</div>
                <div class="register-input-content fl">
                  <input
                    type="password"
                    class="long-input phone-input reg-input borderRed"
                    id="passwordInput"
                    placeholder="请输入六位或六位以上的密码"
                    autocomplete="off"
                    v-model="password"
                  />
                </div>
              </div>
              <div class="register-input-item clear">
                <div class="register-input-label fl label-require" style="line-height: 34px;">确认密码</div>
                <div class="register-input-content fl">
                  <input
                    type="password"
                    class="long-input phone-input reg-input borderRed"
                    id="passwordConfirm"
                    placeholder="请再次输入密码"
                    autocomplete="off"
                    v-model="passwordConfirm"
                  />
                </div>
              </div>
              <div class="register-input-item clear">
                <div class="register-input-label fl label-require">验证码</div>
                <div class="register-input-content fl">
                  <input
                    type=""
                    class="fl code-input reg-input"
                    placeholder="请输入短信验证码"
                    autocomplete="off"
                    v-model="authCode"
                  />
                  <a class="fl get-code checkReg" :class="isDisabled?'code-disabled':'code-able'" id="regnote"
                     :disabled='isDisabled' @click="getAuthCode">{{ buttonText }}</a>
                </div>
                <div class="clear item-error code-error"></div>
              </div>
            </div>
            <!--  -->
          </div>
          <div class="btn-content clear">
            <div class="register-input-item clear protocol-item">
              <div class="register-input-label fl" style="line-height:21px;"><span v-html="'&nbsp;'"></span></div>
              <div class="register-input-content fl register-info">
                <label class="myCheck fl cur"></label>
                <span class="fl">
                  我已认真阅读并同意集元小样的
                  <a href="#" target="_blank">《用户协议》</a>
                </span>
              </div>
            </div>
            <div class="register-input-item clear">
              <div class="register-input-label fl"><span v-html="'&nbsp;'"></span></div>
              <div class="register-input-content fl">
                <div class="form-submit-btn" :class="isRegisterAble?'form-submit-btn-able':'form-submit-btn-disabled'"
                     id="regsubBtn" @click="postRegister">注册
                </div>
              </div>
            </div>
            <!-- <div class="register-input-item clear ">
        <div class="register-input-label fl" style="line-height:21px;">&nbsp;</div>
            </div>-->
          </div>
        </div>
      </form>
    </div>
    <NavFoot/>
  </div>
</template>

<script>
import NavFoot from '@/components/NavFoot.vue'
import { getAuthCodeAPI, postRegisterAPI } from '@/api/apis.js'

export default {
  name: 'Register',
  components: {
    NavFoot
  },
  data () {
    return {
      buttonText: '获取验证码',
      isDisabled: false, // 是否禁止点击发送验证码按钮
      isRegisterAble: false,
      flag: true,

      company_name: '',
      phone: '',
      password: '',
      passwordConfirm: '',
      authCode: ''
    }
  },
  watch: {
    authCode (val, oldVal) {
      if (val.length >= 2) {
        this.isRegisterAble = true
      } else {
        this.isRegisterAble = false
      }
    }
  },
  methods: {
    getAuthCode () {
      if (this.phone.length >= 11) {
        getAuthCodeAPI({ telephone: this.phone })
          .then(res => console.log(res))
          .catch(err => console.log(err))
        let time = 30
        this.buttonText = '已发送'
        this.isDisabled = true
        if (this.flag) {
          this.flag = false
          const timer = setInterval(() => {
            time--
            this.buttonText = time + ' 秒'
            if (time === 0) {
              clearInterval(timer)
              this.buttonText = '重新获取'
              this.isDisabled = false
              this.flag = true
            }
          }, 1000)
        }
      } else {
        this.$message({
          message: '请输入正确的手机号码！',
          type: 'warning'
        })
      }
    },
    postRegister () {
      if (this.company_name.length < 2) {
        this.$message({
          message: '请输入公司名称或昵称！',
          type: 'warning'
        })
        return
      }
      if (this.phone.length < 11) {
        this.$message({
          message: '请输入正确的手机号码！',
          type: 'warning'
        })
        return
      }
      if (this.password.length < 6) {
        this.$message({
          message: '请输入六位或六位以上的密码！',
          type: 'warning'
        })
        return
      }
      if (this.passwordConfirm !== this.password || this.passwordConfirm.length < 6) {
        this.$message({
          message: '两次输入的密码不一致，请确认输入的密码！',
          type: 'warning'
        })
        return
      }
      if (this.authCode.length === 0) {
        this.$message({
          message: '请输入获取的验证码！',
          type: 'warning'
        })
        return
      }
      postRegisterAPI({
        authCode: this.authCode,
        password: this.password,
        telephone: this.phone,
        username: this.company_name
      }).then(res => {
        if (res.status === 200 && res.data.code === 200) {
          this.$message({
            message: '注册成功！',
            type: 'success'
          })
          this.$router.push({ name: 'Login' })
        }
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.register {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

#main {
  position: relative;
  flex: 1;
}

#header {
  background: transparent;
  height: 110px;
  width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.header-content {
  margin: 0 auto;
  padding: 0 30px;
}

.logo {
  margin-top: 15px;
}

.fl {
  float: left;
}

.logo a {
  display: block;
}

.header-contact {
  line-height: 110px;
  color: #fff;
  padding-left: 25px;
  cursor: pointer;
}

.header-contact-grey {
  color: #333333;
}

.fr {
  float: right;
}

.header-line {
  background: #000;
  width: 100%;
  height: 1px;
  bottom: 0;
  opacity: 0.2;
}

.go-login {
  margin: 0 auto;
  margin-top: 32px;
  width: 840px;
  height: 18px;
  margin-bottom: 10px;
}

.login-info {
  color: #aaa;
}

.login-info a {
  color: #EF5121;
}

form {
  display: block;
  margin-top: 0em;
  margin: 0;
}

.register-content {
  width: 760px;
  margin: 0 auto;
  background: #fff;
  padding: 40px;
  padding-top: 30px;
  position: relative;
  z-index: 2;
}

.register-title {
  font-size: 28px;
  text-align: center;
}

.register-form {
  margin-top: 20px;
}

.register-input-item {
  overflow: hidden;
  margin-bottom: 30px;
}

.clear {
  clear: both;
}

.register-input-label {
  width: 228px;
  text-align: right;
  margin-right: 20px;
  line-height: 45px;
  font-size: 14px;
  color: #333;
}

.label-require:before {
  position: relative;
  content: "*";
  color: #EF5121;
  left: -1px;
  top: 2px;
}

.borderRed {
  background: #faf5f5;
  border: 1px solid #EF5121;
}

input {
  vertical-align: middle;
  margin: 0;
  padding: 0;
  outline: 0;
}

input.long-input {
  width: 308px;
}

.register-input-item input {
  height: 43px;
  padding: 0;
  line-height: 15px;
  font-size: 14px;
  padding-left: 10px;
  color: #333;
  line-height: 43px \9;
  border-color: #e0e0e0;
}

input.code-input {
  margin-right: 10px;
  width: 178px;
  border: 1px solid #e0e0e0;
}

a.get-code {
  height: 43px;
  line-height: 43px;
  font-size: 14px;
  width: 118px;
  text-align: center;
  color: #EF5121;
  border-radius: 2px;
  border: 1px solid #f2a588;
  background: #fcf0eb;
}

.code-disabled {
  pointer-events: none;
}

.code-able {
  pointer-events: auto;
}

label.myCheck.cur {
  background: url(../assets/img/create_tick_agree.png) no-repeat center;
}

label.myCheck {
  background: url(../assets/img/commodity_frame_default.png) no-repeat left 0;
  padding-left: 15px;
  margin-right: 5px;
  height: 21px;
  background-position: center;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}

.register-info span {
  font-size: 14px;
  color: #aaa;
}

.register-info a {
  font-size: 14px;
  color: #333;
  font-weight: 700;
}

div.form-submit-btn {
  width: 320px;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  text-align: center;
}

div.form-submit-btn-able {
  background: #f55d22;
  color: #fff;
  cursor: pointer;
}

div.form-submit-btn-disabled {
  color: #aaa;
  background: #eee;
  cursor: not-allowed;
}

</style>
