// 导入axios
import axios from 'axios'
// 使用element-ui Message做消息提醒
import { Message } from 'element-ui'
import router from '@/router'
// 1. 创建新的axios实例，
const service = axios.create({
  // 公共接口--这里注意后面会讲
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 30 * 1000
})
// 2.请求拦截器
service.interceptors.request.use(config => {
  // 发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
  if (config.data) {
    config.data = JSON.stringify(config.data) // 数据转化,也可以使用qs转换
    config.headers = {
      'Content-Type': 'application/json;charset=UTF-8' // 配置请求头
    }
  }
  // 注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
  const storage = window.localStorage
  const token = storage.getItem('token')
  console.log('token:' + token)
  if (token) {
    config.headers.Authorization = `Bearer ${token}` // 配置请求头
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
  // 接收到响应数据并成功后的一些共有的处理，关闭loading等
  const message = response.data.message
  console.log('response:' + message)
  if (response.data.code !== 200) {
    if (response.data.code === 401) {
      router.replace({ name: 'Login' })
    }
    if (message) {
      Message.error(message)
    }
  }
  return response
}, error => {
  // 接收到异常响应的处理开始
  if (error && error.response) {
    // 1.公共错误处理
    // 2.根据响应码具体处理
    let message = error.response.message
    const errCode = error.response.status
    switch (error.response.status) {
      case 400:
        message = '错误请求'
        break
      case 401:
        message = '未授权，请重新登录'
        router.replace({ name: 'Login' })
        break
      case 403:
        message = '拒绝访问'
        break
      case 404:
        message = '请求错误,未找到该资源'
        window.location.href = '/NotFound'
        break
      case 405:
        message = '请求方法未允许'
        break
      case 408:
        message = '请求超时'
        break
      case 500:
        message = '服务器端出错'
        break
      case 501:
        message = '网络未实现'
        break
      case 502:
        message = '网络错误'
        break
      case 503:
        message = '服务不可用'
        break
      case 504:
        message = '网络超时'
        break
      case 505:
        message = 'http版本不支持该请求'
        break
      default:
        message = `连接错误${errCode}`
    }
    if (message) {
      Message.error(message)
    }
  } else {
    // 超时处理
    if (JSON.stringify(error).includes('timeout')) {
      Message.error('服务器响应超时，请刷新当前页')
    }
    error.message = '连接服务器失败'
    Message.error(error.message)
  }
  // 处理结束
  // 如果不需要错误处理，以上的处理过程都可省略
  return Promise.resolve(error.response)
})

// 4.导入文件
export default service
