<template>
  <!--footer area start-->
  <div class="footer_area">
    <div class="copyright_content">
      <p>
          Copyright &copy; 2019, <a href="/">集元小样</a>. All Rights Reserved.
          备案号：<a target="_blank" href="http://www.beian.miit.gov.cn"
            >粤ICP备19004863号</a
          >
       </p>
    </div>
  </div>
  <!--footer area end-->
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.footer_area {
  background: #EF5121;
  padding: 18px 0;
  width: 100%;
}
.copyright_content{
  margin: 0 auto;
  width: 1200px;
}
.copyright_content p, .copyright_content p a {
    color: #ffffff;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400;
    line-height: 23px;
}
</style>
