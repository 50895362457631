<template>
  <div class="home">
    <NavHead />
    <div class="ban">
      <el-carousel height="25vw">
        <el-carousel-item  v-for="(item,index) in homeData.advertiseList" :key="index">
          <el-image fit="scale-down" :src=item.pic ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="box-three">
      <div class="dyinc-box fl-l">
        <div class="gay-title">
          <span>热门品牌</span>
          <div class="brand">
            <ul class="clear fl-l">
              <li class="fl-l" v-for="(item,index) in homeData.brandList" :key="index">
                <router-link
                  :to="{path:'#'}"
                  target="_blank"
                  :title=item.name>
                  <el-image fit="scale-down" :src=item.logo :alt=item.name style="width: 133px;height: 78px;"/>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="dyinc-con">
          <div class="home-bar-c"></div>
        </div>
      </div>
      <div class="xjbox fl-l">
        <div class="gay-title">
          <span>快速询价</span>
        </div>
        <div class="xj-con">
          <div class="xj-group clear">
            <div class="fl-l">
              <span class="f-red">*</span>
              联系人手机号
            </div>
            <div class="fl-r">
              <span class="f-red">*</span>
              型号/参数
            </div>
          </div>
          <div class="xj-group clear xj-group-con">
            <div class="fl-l">
              <input type="text" placeholder="请输入手机号码" class="xj-mobile" v-model="phone"/>
              <div class="xj-sms-code-box clear yes-fast" style="">
                <input type="text" placeholder="短信验证码" class="xj-code-val fl-l" v-model="authCode">
                <a class="fl get-code checkReg" :class="isDisabled?'code-disabled':'code-able'" id="regnote"
                   :disabled='isDisabled' @click="getAuthCode">{{ buttonText }}</a>
              </div>
            </div>
            <div class="fl-r">
              <input type="text" placeholder="请输入物料型号" class="xj-goods-name" maxlength="50" v-model="name_model"/>
            </div>
          </div>
          <div class="xj-btns clear">
            <div class="now-xj fl-r" @click="postEnquiryOrder">立即询价</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-four">
      <div class="box-four-top">
        <div class="zy-c-head">
          <div class="head-con">热销物料</div>
        </div>
        <div class="four-con">
          <div class="zy-co">
            <ul class="zy-group-c">
              <li class="clear fl-l">
                <div class="group-one-c-box fl-l" v-for="(item, index) in homeData.hotProductList" :key="index">
                  <div class="group-one-c">
                    <router-link :to="{name:'Product'}" :title=item.name target="_self" class="price">
                                                            <div class="pic">
                                                                <img :src=item.pic :alt=item.name>
                                                            </div>
                                                            <p class="t1" :title=item.name>
                                                              {{item.name}}</p>
                                                            <p class="t2">￥{{item.price}}</p>
                                                        </router-link>
                    <router-link class="tipsxp" :to="{name:'Product'}" :title=item.productCategoryName>{{ item.productCategoryName }}</router-link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <NavSfot />
    <NavFoot />
  </div>
</template>

<script>
// @ is an alias to /src
import NavHead from '@/components/NavHead.vue'
import NavFoot from '@/components/NavFoot.vue'
import NavSfot from '@/components/NavSfot.vue'
import { getAuthCodeAPI, getHomeContentAPI, postEnquiryOrderAPI } from '@/api/apis.js'

export default {
  name: 'Home',
  components: {
    NavHead,
    NavFoot,
    NavSfot
  },
  data () {
    return {
      buttonText: '获取验证码',
      isDisabled: false, // 是否禁止点击发送验证码按钮
      isRegisterAble: false,
      flag: true,

      phone: '',
      name_model: '',
      authCode: '',
      homeData: {}
    }
  },

  methods: {
    getHomeContent () {
      getHomeContentAPI()
        .then(res => {
          this.homeData = res.data.data
          console.log(res)
        })
        .catch(err => console.log(err))
    },
    getAuthCode () {
      if (this.phone.length >= 11) {
        getAuthCodeAPI({ telephone: this.phone })
          .then(res => console.log(res))
          .catch(err => console.log(err))
        let time = 30
        this.buttonText = '已发送'
        this.isDisabled = true
        if (this.flag) {
          this.flag = false
          const timer = setInterval(() => {
            time--
            this.buttonText = time + ' 秒'
            if (time === 0) {
              clearInterval(timer)
              this.buttonText = '重新获取'
              this.isDisabled = false
              this.flag = true
            }
          }, 1000)
        }
      } else {
        this.$message({
          message: '请输入正确的手机号码！',
          type: 'warning'
        })
      }
    },
    postEnquiryOrder () {
      if (this.phone.length < 11) {
        this.$message({
          message: '请输入正确的手机号码！',
          type: 'warning'
        })
        return
      }
      if (this.name_model.length === 0) {
        this.$message({
          message: '请输入物料型号！',
          type: 'warning'
        })
        return
      }
      if (this.authCode.length === 0) {
        this.$message({
          message: '请输入获取的验证码！',
          type: 'warning'
        })
        return
      }

      postEnquiryOrderAPI({ telephone: this.phone, authCode: this.authCode, name_model: this.name_model })
        .then(res => {
          this.$message({
            message: '询价成功！',
            type: 'success'
          })
          console.log(res)
          this.phone = ''
          this.name_model = ''
        })
        .catch(err => console.log(err))
    }
    //  promise调用，链式调用， getList()括号内只接受参数；
    //    get不传参
    //   getList() {
    //     getListAPI().then(res => console.log(res)).catch(err => console.log(err))
    //   },
    //    post传参
    //   postForm(formData) {
    //     let data = formData
    //     postFormAPI(data).then(res => console.log(res)).catch(err => console.log(err))
    //   },

    //    async await同步调用
    //   async postForm(formData) {
    //     const postRes = await postFormAPI(formData)
    //     const putRes = await putSomeAPI({data: 'putTest'})
    //     const deleteRes = await deleteListAPI(formData.name)
    //     // 数据处理
    //     console.log(postRes);
    //     console.log(putRes);
    //     console.log(deleteRes);
    //   },
  },

  mounted: function () {
    this.getHomeContent()
  }
}
</script>

<style lang="less" scoped>
.home{
  width: 100%;
}
.el-carousel__item {
  text-align: center;
}

.el-image {
  width: 100%;
  height: 100%;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.brand ul {
  width: 700px;
  height: 192px;
}
.fl-l,
.fl {
  float: left;
}
.clear,
.clr {
  display: block;
  min-height: 1%;
}
.brand li {
  width: 140px;
  height: 86px;
}
.fl-l,
.fl {
  float: left;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #333333;
}
.brand li img {
  display: block;
  margin: 0 auto;
  width: 140px;
  height: 86px;
  transition: all 0.4s ease;
}

.box-three {
  height: 259px;
  width: 1200px;
  margin: 0 auto;
  background: #f8f8f8;
}
.dyinc-box {
  width: 700px;
  height: 259px;
  margin-right: 10px;
}
.box-three .gay-title {
  height: 43px;
  line-height: 43px;
}
.dyinc-con {
  background: #fff;
  height: 216px;
}
.home-bar-c {
  padding: 0 15px;
  width: 382px;
  height: 186px;
}
.box-three .gay-title span {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-left: 20px;
}
.xjbox {
  width: 490px;
  height: 259px;
}
.xj-con {
  background: #fff;
  height: 216px;
  padding: 0 20px;
  font-size: 14px;
  color: #333;
}
.box-three .xj-group .fl-l {
  width: 160px;
  height: 48px;
  line-height: 48px;
}
.box-three .xj-group .fl-r {
  width: 275px;
  height: 48px;
  line-height: 48px;
}
.f-red {
  color: #ff0000 !important;
}
.box-three .xj-group .fl-l input {
  width: 150px;
  height: 40px;
  line-height: 40px;
}
.box-three .xj-group input {
  border: 1px solid #e1e1e1;
  background: #f8f8f8;
  border-radius: 2px;
  outline: none;
  font-size: 14px;
  padding-left: 10px;
  transition: all 0.5s;
}
.box-three .xj-group .fl-r input {
  width: 265px;
  height: 40px;
  line-height: 40px;
}
.box-three .xj-group input {
  border: 1px solid #e1e1e1;
  background: #f8f8f8;
  border-radius: 2px;
  outline: none;
  font-size: 14px;
  padding-left: 10px;
  transition: all 0.5s;
}
.box-three .xj-group .xj-sms-code-box {
  width: 300px;
  margin-top: 10px;
}
.box-three .xj-group .fl-l input {
  width: 150px;
  height: 40px;
  line-height: 40px;
}
.code-disabled {
  pointer-events: none;
}

.code-able {
  pointer-events: auto;
}
.fl-r,
.fr {
  float: right;
}
.box-three .now-xj {
  width: 82px;
  height: 34px;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #EF5121;
  color: #EF5121;
  font-size: 14px;
  text-align: center;
  line-height: 34px;
  margin-top: 66px;
  cursor: pointer;
  transition: all 0.5s;
}
a.get-code {
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  width: 90px;
  margin-left: 10px;
  text-align: center;
  color: #fff;
  border-radius: 2px;
  background: #EF5121;
}

.box-four{
  width: 1200px;
  margin: 0 auto;
}
.box-four-top {
    height: 281px;
}
.box-four .zy-c-head {
    font-size: 16px;
    color: #333;
    font-weight: bold;
    position: relative;
    background: #f8f8f8;
    height: 43px;
    line-height: 43px;
}
.box-four .head-con {
    margin-left: 20px;
}

.box-four .four-con {
    width: 1200px;
    margin: 0 auto;
    background: white;
}
.box-four .zy-co {
    position: relative;
    width: 1200px;
    height: 238px;
    overflow: hidden;
}
.box-four .zy-group-c {
    height: 238px;
    width: 9999px;
    position: absolute;
    left: 0px;
}
.box-four .zy-group-c li {
    width: 1200px;
    height: 238px;
}
.box-four .zy-group-c li .group-one-c-box {
    width: 170px;
    height: 238px;
    margin-left: 25px;
}
.box-four .zy-group-c li .group-one-c {
    width: 170px;
    margin: 0 auto;
    height: 238px;
    position: relative;
}
.box-four .zy-group-c li .group-one-c:hover {
    transform: translatey(-3px);
    box-shadow: 0px 3px 13px 0px rgba(4, 0, 0, 0.15);
}
.box-four  .zy-group-c li .group-one-c .pic img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    display: block;
}
.box-four  .zy-group-c li .group-one-c .t1 {
    font-size: 14px;
    color: #666;
    line-height: 20px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    margin: 0 auto;
    height: 42px;
    white-space: normal;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: all 0.4s ease;
}
.box-four  .zy-group-c li .group-one-c .t2 {
    font-size: 16px;
    color: #EF5121;
    line-height: 38px;
    height: 38px;
    text-align: center;
}
.box-four  .zy-group-c li .group-one-c .tipsxp {
    position: absolute;
    width: 154px;
    height: 36px;
    background: rgba(240, 247, 255,0.7);
    font-size: 12px;
    color: #666;
    padding: 8px;
    left: 0px;
    top: 60px;
    overflow: hidden;
}

</style>
