<template>
  <!--header area start-->
    <!--header top start-->
  <div class="header_top">
      <div class="hd-bd">
        <div class="reg">
          <span>您好，欢迎来到集元小样</span>
        </div>
        <div class="logout" :class="isLogin?'login':'login_no'" @click="logout"><span>|</span>退出</div>
        <div class="enter">
          <span class="er04 blue phone"><b></b> 400-830-2058</span>
          <a class="qq blue" id="forQQ" qq="4000800709"><b></b>4000800709</a>
          <div class="member-list-wrap" id="center-wrapper-btn" :class="isLogin?'login':'login_no'">
            <router-link :to="{path:'/member/center'}" class="er01 my-member">个人中心<b></b></router-link>
            <div class="member-list" style="display: none;">
              <dl>
                <dd>
                  <a class="my-order" rel="nofollow" href="#">我的订单</a>
                  <span>|</span>
                  <a class="my-collection" href="#">我的收藏</a>
                </dd>
                <dd>
                  <a class="complaints-sale" rel="nofollow" href="#">售后投诉</a>
                  <span>|</span>
                  <a class="my-discount-coupon" rel="nofollow" href="#">我的优惠券</a>
                </dd>
                <dd>
                  <a class="prepaid-balance" rel="nofollow" href="#">预付余额</a>
                  <span>|</span>
                  <a class="shipping-address" rel="nofollow" href="#">收货地址</a>
                </dd>
              </dl>
            </div>
          </div>
          <div class="reg" :class="isLogin?'login_no':'login'">
            <router-link  id="register" rel="nofollow" :to="{name:'Register'}">免费注册</router-link>
            <router-link  id="login" rel="nofollow" :to="{name:'Login'}">请登录</router-link>
          </div>
        </div>
      </div>
    </div>
    <!--header top start-->
</template>

<script>

export default {
  name: 'nvaHead',
  data () {
    return {
      isLogin: false
    }
  },
  mounted () {
    this.getIsLogin()
  },
  methods: {
    logout () {
      localStorage.removeItem('token')
      localStorage.removeItem('tokenTime')
      localStorage.removeItem('memberId')
      this.$router.push({ path: '/home' })
      this.isLogin = false
    },
    getIsLogin () {
      const token = localStorage.getItem('token')
      if (token) {
        this.isLogin = true
      } else {
        this.isLogin = false
      }
    },
    showCatalogs () {
      this.isShowCatalogs = true
    },
    hideCatalogs () {
      this.isShowCatalogs = false
    }
  }
}
</script>

<style lang="less" scoped>
.header_top {
  width: 100%;
  height: 40px;
  background: #f2f2f2;
}

.header_top .hd-bd {
  position: relative;
  margin: 0 auto;
  width: 1200px;
  height: 40px;
  line-height: 40px;
}

.header_top .hd-bd .reg {
  float: left;
  margin-right: 5px;
  width: auto;
  color: #666;
  font-size: 12px;
  height: 40px
}
.login{
  display: block;
}
.login_no{
  display: none;
}
.header_top .hd-bd .enter .member-list-wrap {
  position: relative;
  float: right;
}

.header_top .hd-bd .enter a.er01.er01:hover {
  position: relative;
  z-index: 1200;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  color: #EF5121;
  background: #fff;
}

.header_top .hd-bd .enter a.er01 {
  padding: 0 6px;
  border: 1px solid transparent;
  border-top: none;
  line-height: 40px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.header_top .hd-bd .enter a {
  float: right;
  color: #666;
  margin-right: 8px;
  align-items: center;
  font: 12px / 18px "Microsoft YaHei", '微软雅黑', Arail;
}

a {
  color: #444;
  text-decoration: none;
  cursor: pointer;
}

.header_top .hd-bd .enter .member-list-wrap .member-list {
  position: absolute;
  top: 39px;
  right: 0;
  z-index: 1112;
  display: none;
  margin: 0;
  padding-bottom: 8px;
  width: 185px;
  margin-right: 8px;
  border: 1px solid #e5e5e5;
  background: #fff;
}
.header_top .hd-bd .enter .member-list-wrap .member-list dl {
  margin: 10px 0 0 15px;
  font-size: 14px;
}

.header_top .hd-bd .enter .member-list-wrap .member-list dl dd {
  float: left;
  width: 100%;
  line-height: 26px;
  margin-inline-start: 0px;
}

.header_top .hd-bd .enter .member-list-wrap .member-list dl dd a {
  float: left;
  padding-left: 0;
  color: #666;
  line-height: 26px;
  font-size: 14px;
}
.header_top .hd-bd .enter .member-list-wrap .member-list dl dd span {
  margin: 0 12px;
  float: left;
  color: #bdbdbd;
}

.header_top .hd-bd .logout {
  float: right;
  padding-left: 6px;
  cursor: pointer;
  height: 40px;
  margin-top: 11px;
  font: 12px / 18px "Microsoft YaHei", '微软雅黑', Arail;
}

.header_top .hd-bd .logout span {
  margin-right: 6px;
  color: #ccc;
}
dl {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.header_top .hd-bd .enter {
  position: relative;
  z-index: 1;
  float: right;
  color: #EF5121;
}

.header_top .hd-bd .reg a {
  margin: 0 4px;
  color: #9a9a9a;
  line-height: 40px;
}

.header_top .hd-bd .enter a {
  float: right;
  color: #666;
}

.header_top .hd-bd .reg #register {
  font-weight: 700;
  color: #666;
}

.header_top .hd-bd .reg #login {
  font-weight: 700;
  color: #EF5121;
}

.header_top .hd-bd .enter span.er04 {
  float: right;
  color: #EF5121;
  font-weight: 700;
}

.header_top .hd-bd .enter span.er04 b {
  margin-right: 2px;
  display: inline-block;
  width: 18px;
  height: 18px;
  vertical-align: text-top;
  background: url(../assets/img/phone.svg) no-repeat;
  background-size:100% 100%;
}

.header_top .hd-bd .enter a.qq b {
  display: inline-block;
  width: 16px;
  height: 18px;
  background: url(../assets/img/qq.svg) no-repeat;
  background-size:100% 100%;
  vertical-align: text-bottom;
  margin-right: 4px;
}

.header_top .hd-bd .enter a.qq {
  float: right;
  width: auto;
  height: 40px;
  line-height: 40px;
  margin-right: 18px;
  color: #666;
  background-position: 0 -61px;
}
</style>
