<template>
  <div class="order">
    <NavHeadTop/>
    <header id="header">
      <div class="w1200 clear head-text head-textx2">
        <div class="logo-nav logo-nav2x">
          <a href="/" class="logo"><img alt="提交订单" src="../assets/img/logo-big.png"></a>
          <span class="string"></span>

          <h2 class="fw">提交订单</h2>
        </div>
      </div>
    </header>
    <div class="w1200 clear">
      <h3 class="order-h3">填写并核对订单信息</h3>
      <div class="order-detail">
        <div class="box-one">
          <h4>配送方式</h4>
          <ul class="clear delivery-tab">
            <li class="act kdnav" guid="1">快递配送<i></i></li>
<!--            <li class="ztnav" guid="2">上门自提<i></i></li>-->
          </ul>
          <div class="cont shBox">
            <div class="block kdBox">
              <h5 style="position: relative;left:-20px;">收货人信息</h5>
              <div class="express">
                <ul class="addressData">
                  <li class="act">
                    <p class="fl-r operation">
                    <a href="javascript:void('0')" style="display:none">默认地址</a>
                    <a href="javascript:void('0')" class="editList" guid="11211">编辑</a>
                    <a href="javascript:void('0')" class="delList" guid="11211">删除</a>
                    </p>
                  <strong title="11东城区" province="北京" provinceid="2" city="北京" cityid="52" district="东城区" districtid="500">11 东城区</strong>
                    <p class="fl-l"><span class="csName">11</span><span class="csMobile">18575572592</span><span class="csAddress">111</span></p> <b>默认地址</b> </li></ul>
                <p class="mb-20"><a href="javascript:void('0')" class="f-blue addPop">添加新的收货人地址</a></p>
              </div>
            </div>
          </div>
        </div>
        <div class="box-three">
          <h4 style="display: inline-block;">发票信息</h4>
          <div class="tipArrow" style=""><img src="https://static.ichunt.com/dist/res/home/images/tipArrow.png" alt="提交订单"></div>
          <div class="tipBox" style="">
            <span class="tipTitle">提醒：</span>
            <span class="tipMsg">增值税普通发票，将随货一起快递给您。</span>
          </div>
          <div class="invoice">
            <ul>
              <li class="act">
                <p class="fl-r operation"></p>
                <label><span class="check-img"><i class="icon-check noInv" guid="0"></i></span>我不开发票，谢谢！</label>
              </li>
            </ul>
            <ul class="invoiceData">
              <li class="act"> <p class="fl-r operation">
                <a href="javascript:void('0')" style="display:none" guid="10063">默认发票</a>
                <a href="javascript:void('0')" class="editListV_common" guid="10063">编辑</a>
                <a href="javascript:void('0')" class="delListV" guid="10063">删除</a>
              </p>
                <label><span class="check-img"><i class="common icon-check act-check" guid="10063"></i></span>
                  <div class="tag">普通<i class="icon-tag"></i></div>
                  <span class="w-130">11</span><span class="w-130"></span>
                </label>
                <b class="ml-10">默认发票</b>
              </li>
              <li class="">
                <p class="fl-r operation">
                  <a href="javascript:void('0')" class="setV" guid="10062">设为默认发票</a>
                  <a href="javascript:void('0')" class="editListV_common" guid="10062">编辑</a>
                  <a href="javascript:void('0')" class="delListV" guid="10062">删除</a></p>
                <label>
                  <span class="check-img"><i class="common icon-check " guid="10062"></i>
                  </span><div class="tag">普通<i class="icon-tag"></i></div>
                  <span class="w-130">111</span><span class="w-130"></span>
                </label>
                <b class="ml-10">默认发票</b>
              </li>
            </ul>
            <p>
              <a href="javascript:void('0')" class="f-blue addPop_invoice">增值税普通发票</a>
              <a style="margin-left: 20px" href="javascript:void('0')" class="f-blue addPop_invoice_s">增值税专用发票</a>
            </p>
          </div>
        </div>
        <div class="box-four">
          <h4>商品信息</h4>
          <div class="cont">
            <a href="/v3/joincart" class="return f-blue">返回购物车修改</a>
            <div class="block lyDataShow showBox">
              <ul class="title">
                <!-- <li class="w170">供应商</li> -->
                <li class="w260">商品型号</li>
                <li class="w240">详细</li>
                <li class="w100">单价</li>
                <li class="w100">货期</li>
                <!-- <li class="w90">标准包装量</li> -->
                <li class="w90">购买量</li>
                <li class="w120">小计</li>
                <li class="w190">备注</li>
              </ul>
              <table width="100%" border="0" cellpadding="0" cellspacing="0">
                <tbody class="lianyingData"><tr><td><ul class="list clear"><li class="w260"><a target="_blank" href="https://www.ichunt.com/goods_33884.html" class="name">TM1723 LCD驱动 SOP-32_300mil</a></li><li class="w260"><p>制造商：TM(天微)</p><p>供应商：现货自营</p></li><li class="w100">¥1.4300</li> <li class="w100">立即发货</li><li class="w90">20</li><li class="w120"><b class="font f-red">¥28.60</b></li><li class="w190"><input type="text" cart_id="216687" class="remark-input" placeholder="请输入商品描述" value=""></li></ul></td></tr><tr><td><ul class="list clear"><li class="w260"><a target="_blank" href="https://www.ichunt.com/goods_33883.html" class="name">TM1621D LCD驱动 SOP-24_300mil</a></li><li class="w260"><p>制造商：TM(天微)</p><p>供应商：现货自营</p></li><li class="w100">¥0.8470</li> <li class="w100">立即发货</li><li class="w90">30</li><li class="w120"><b class="font f-red">¥25.41</b></li><li class="w190"><input type="text" cart_id="216686" class="remark-input" placeholder="请输入商品描述" value=""></li></ul></td></tr></tbody>
              </table>
            </div>

            <div class="remark"><span class="fw">商品信息：</span><input type="text" name="" class="marksp" placeholder="请填写有关支付、收货、发票方面的信息"></div>
            <div class="zpgoods clear zpshos" style="display: none;">
              <h6>赠品信息</h6>
              <div class="clear zpdatagoods">
                <div class="gp fl-l">
                  <img class="fl-l" src="" alt="猎芯网">
                  <div class="zpinfo fl-l">
                    <p>京东卡100元</p>
                    <p class="numgh">数量：1</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 优惠劵 -->
        <div class="box-six" style="display:none;">
          <h4><span class="fl-r order-coupon-but"><i class="icon-top"></i>更多</span>使用优惠劵</h4>
          <div class="cont">
            <div class="hint">无相关优惠劵</div>
            <div class="quan-coupon coupon-box" style="display:none;">
              <div class="order-coupon clear"></div>
            </div>
          </div>
        </div>
        <div class="clear box-five">
          <div class="zp-box">

          </div>
          <ul>
            <li class="fw totalY">¥54.01</li>
            <li class="font fjfx fjfxfy" style="display:none"></li>
            <li class="font ziyf ziyformat">¥13.00</li>
            <li class="preferential font">¥0.00</li>
            <li class="xkjprice font"></li>
            <li class="zfpoint font">109</li>
          </ul>
          <ul class="pr-50">
            <li>共<span class="f-red numGoogs">2</span> 件商品，共计商品总金额：</li>
            <li class="fjfx fjfxbox" style="display:none">
              <div class="hint hint2"><i class="icon-hint"> </i>
                <p class="f-orange"><i class="icon-arrows"></i>
                  <span class="fjdata">
                        </span>
                </p>
              </div>
              附加费：
            </li>
            <li class="ziyf">
              <div class="hint hint1">
                <svg class="icon" aria-hidden="true"><use xlink:href="#icon-wenhao"></use></svg>
                <p class="f-orange" style="white-space: normal"><i class="icon-arrows"></i>
                  运费说明：<br>
                  1、收件地址在深圳市内，运费为8元/单，在广东省内，运费为10元/单，在广东省外（大陆境内）运费为13元/单；  <br>
                  2、吉林、黑龙江、辽宁、新疆、西藏、内蒙古、青海、宁夏与港澳台为18元一单<br>
                  <span class="f-999">*以上说明解释权归猎芯网所有</span>
                </p></div>
              运费：
            </li>
            <li>
              <div class="hint"><svg class="icon" aria-hidden="true"><use xlink:href="#icon-youhui"></use></svg><p class="f-orange"><i class="icon-arrows"></i>优惠仅针对商品金额，不包括快递费用</p></div>
              优惠金额：
            </li>
            <li class="xkj-li" style="display: none">
              新客价优惠金额：
            </li>
            <li>
              交易成功获得积分：
            </li>
          </ul>
        </div>
      </div>
      <ul class="order-price">
        <li>
          <span class="f-black">订单金额：</span>
          <b class="f-red totalMoney">¥0.0000 </b>
        </li>

        <li>
          <a href="javascript:void('0')" class="but but-red submitOrder">提交订单</a>
        </li>
      </ul>
    </div>
    <NavSfot/>
    <NavFoot/>
  </div>
</template>

<script>
import NavHeadTop from '@/components/NavHeadTop'
import NavFoot from '@/components/NavFoot'
import NavSfot from '@/components/NavSfot'

export default {
  name: 'Order',
  components: {
    NavHeadTop,
    NavFoot,
    NavSfot
  }
}
</script>

<style scoped>
.order{
  width: 100%;
  background: #f6f6f6;
}
#header {
  height: 106px;
  min-width: 1200px;
  background: #ffffff;
}
.head-text {
  height: 76px;
  padding: 15px 0;
}

.w1200 {
  margin: 0 auto;
  max-width: 1200px;
  min-width: 1200px;
}
.clear, .clr {
  display: block;
  min-height: 1%;
}
.head-text .logo-nav {
  float: left;
  width: 387px;
  margin-right: 40px;
}
.head-text .logo-nav .logo {
  float: left;
  display: block;
}
.head-text .logo-nav img {
  display: block;
  margin: 0 auto;
  float: left;
  width: 220px;
  height: 76px;
}
.head-text .logo-nav .string {
  float: left;
  overflow: hidden;
  display: inline-block;
  margin: 15px 20px;
  width: 1px;
  height: 40px;
  background-color: #d4d4d4;
}
.head-text .logo-nav h2 {
  font-weight: bold;
  float: left;
  font-size: 16px;
  height: 76px;
  line-height: 76px;
  font-family: "Microsoft YaHei";
  color: #333;
}
.order-h3 {
  font-size: 18px;
  color: #666;
  height: 40px;
  line-height: 40px;
  margin-top: 9px;
}
.order-detail {
  background: #fff;
  padding: 0 20px 20px 20px;
  margin-bottom: 20px;
}
.order-detail .box-one {
  border-bottom: 1px solid #e5e5e5;
}
.order-detail h4 {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  height: 30px;
  line-height: 30px;
  padding: 10px 0;
}
.order-detail .box-one .delivery-tab {
  margin: 0 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}
.order-detail .box-one .delivery-tab li {
  margin-right: 20px;
  color: #666;
  background-color: #fff;
  float: left;
  height: 28px;
  line-height: 28px;
  width: 138px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #e5e5e5;
}

.order-detail .box-one .delivery-tab li.act {
  position: relative;
  height: 26px;
  line-height: 26px;
  width: 136px;
  border: 2px solid #EF5121;
}
.clear:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.order-detail .box-one .cont {
  padding: 0 20px;
}
.order-detail .box-one .block {
  padding-bottom: 30px;
}
.order-detail h5 {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  height: 30px;
  line-height: 30px;
  padding: 10px 0;
}
.order-detail .box-one .express {
  padding: 0 20px;
  padding-left: 0px;
}
.order-detail .box-one .express ul {
  max-height: 150px;
  overflow-y: auto;
}
.order-detail .box-one .express li {
  height: 30px;
  line-height: 30px;
  color: #666666;
  font-size: 12px;
  margin-bottom: 20px;
}
.order-detail .box-one .express li p {
  height: 30px;
  line-height: 30px;
}

.fl-r, .fr {
  float: right;
}
.fl-l, .fl {
  float: left;
}
.order-detail .box-one .express li p a {
  margin-right: 20px;
}
.order-detail .box-one .express li strong {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  margin-right: 20px;
  float: left;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  width: 138px;
  text-align: center;
  border: 1px solid #e5e5e5;
  background: #ffffff;
}
.order-detail .box-one .express li.act strong {
  position: relative;
  height: 26px;
  line-height: 26px;
  width: 136px;
  border: 2px solid #EF5121;
}
.order-detail .box-one .express li p span {
  margin-right: 20px;
}
.order-detail .box-one .express li b {
  margin-top: 5px;
  display: block;
  float: left;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  padding: 0 5px;
  background: #999999;
  color: #ffffff;
}
.order-detail .box-one .express li:hover {
  background: #f1d8d0;
}
a.f-blue{
  color:#EF5121;
  font-size: 14px;
}
a.f-blue:hover {
  font-weight: bold;
}
.mb-20 {
  margin-bottom: 20px;
}
.tipBox {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  border: 1px solid #999;
  -moz-box-shadow: 1px 1px 1px #ddd,-1px -1px 1px #ddd;
  -webkit-box-shadow: 1px 1px 1px #ddd, -1px -1px 1px #ddd;
  box-shadow: 1px 1px 1px #ddd, -1px -1px 1px #ddd;
}
.tipArrow {
  display: inline-block;
  margin-left: 5px;
  margin-right: -3px;
  position: relative;
  z-index: 5;
  vertical-align: middle;
  width: 12px;
  height: 24px;
}
.tipBox .tipTitle {
  vertical-align: middle;
  margin-left: 5px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  color: #ffa200;
  font-family: "Microsoft Yahei";
}
.tipBox .tipMsg {
  vertical-align: middle;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  color: #666;
  font-family: "Microsoft Yahei";
}
.order-detail .box-three .invoice {
  padding: 0 20px;
}
.order-detail .box-three .invoice li {
  height: 30px;
  line-height: 30px;
  margin-bottom: 8px;
  font-size: 14px;
}
.check-img {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 5px;
  position: relative;
  top: 2px;
  cursor: pointer;
}
.check-img .icon-check {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  *display: none;
  z-index: 2;
  width: 14px;
  height: 14px;
}
.f-blue {
  font-size: 14px;
  color: #ffa200;
}
.order-detail .box-three .invoice li:hover {
  background: #f1d8d0;
}
.order-detail .box-three .invoice li label {
  float: left;
}
.order-detail .box-three .invoice li b, .order-detail .box-three .invoice li .operation {
  display: none;
}
.order-detail .box-three .invoice li.act b {
  margin-top: 5px;
  display: block;
  font-size: 12px;
  float: left;
  height: 20px;
  line-height: 20px;
  padding: 0 5px;
  background: #999999;
  color: #ffffff;
}
check-img .act-check {
  background-position: -19px -30px;
}
.check-img .icon-check {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  z-index: 2;
  width: 14px;
  height: 14px;
}
.invoiceData .tag {
  display: inline-block;
  margin: 8px 10px 0 0;
  width: 42px;
  text-align: center;
  position: relative;
  height: 16px;
  line-height: 16px;
  color: #ffa200;
  border: 2px solid #ffa200;
}
.w-130 {
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
}

.order-detail .box-three {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}
.order-detail .box-three .invoice li p a {
  margin-right: 20px;
}

.order-detail .box-four {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}
.order-detail .box-four .cont {
  padding: 0 20px;
  position: relative;
}
.order-detail .box-four .return {
  height: 20px;
  line-height: 20px;
  display: inline-block;
  position: absolute;
  top: -20px;
  right: 20px;
}
.order-detail .box-four .block {
  padding-bottom: 20px;
}
.order-detail .box-four .block .title {
  height: 38px;
  line-height: 38px;
  background: #f8f8f8;
  border: 1px solid #e5e5e5;
  padding-left: 10px;
  font-size: 14px;
}
.order-detail .box-four .block .title li {
  float: left;
  text-align: left;
}
.order-detail .box-four .block .w260 {
  width: 250px;
}
.order-detail .box-four .block .w240 {
  width: 240px;
}
.order-detail .box-four .block .w100 {
  width: 100px;
}
.order-detail .box-four .block .w90 {
  width: 90px;
}
.order-detail .box-four .block .w120 {
  width: 119px;
}
.order-detail .box-four .block .w190 {
  width: 190px;
}
.order-detail .box-four table {
  border: 0;
}
.order-detail .box-four td {
  text-align: left;
  color: #666;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  vertical-align: middle;
  word-wrap: break-word;
  font-size: 14px;
  word-break: break-all;
}
.order-detail .box-four td ul {
  padding: 10px 0;
  height: 52px;
  line-height: 22px;
  border-bottom: 1px solid #e5e5e5;
}
.order-detail .box-four td .name {
  text-align: left;
  padding: 0 20px 0 10px;
  display: inline-block;
  word-wrap: break-word;
  word-break: break-all;
}
.order-detail .box-four .block a {
  color: #EF5121;
}
.clear, .clr {
  display: block;
  min-height: 1%;
}
.order-detail .box-four td li {
  float: left;
}
table {
  border-color: #ccc !important;
}
.f-red {
  color: #ff0000!important;
}
.order-detail .box-four .remark {
  line-height: 30px;
  height: 30px;
}
.order-detail .box-four .remark span {
  float: left;
  display: block;
}
input{
  color: #666666;
  font-size: 12px;
}
.fw {
  font-size: 14px;
  font-weight: bold!important;
}
.order-detail .box-four .remark input {
  float: left;
  color: #666666;
  padding: 0;
  margin: 0;
  height: 18px;
  line-height: 18px;
  width: 1000px;
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #cccccc;
}

.order-detail .box-five {
  padding-top: 10px;
}
.order-detail .box-five ul {
  text-align: right;
  float: right;
  line-height: 28px;
  color: #666;
}
.order-detail .box-five .fw {
  font-size: 14px;
  color: #333;
}
.font {
  color: #333333;
  font-size: 14px;
}

.order-price {
  background: #fff;
  padding: 0 20px 20px 20px;
  margin-bottom: 20px;
  line-height: 45px;
  text-align: right;
}
.order-price span {
  margin-right: 30px;
}
.f-black {
  color: #666666;
}
.order-price b {
  font-size: 18px;
  font-weight: bold;
  float: right;
}
.but-red {
  color: #fff;
  padding: 0 14px;
  text-align: center;
  background: #ff0000;
  margin: 0;
  display: inline-block;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}
.order-price .but {
  padding: 0 24px;
  height: 45px;
  font-size: 18px;
  font-weight: bold;
}
.order-detail .box-five .hint1 p {
  display: none;
  right: -35px;
  background: #fff;
  top: 30px;
  padding: 10px;
  height: auto;
  text-align: left;
  line-height: 20px;
  width: 290px;
  position: absolute;
  border: 1px solid #ffa200;
  white-space: nowrap;
  z-index: 1;
}
.order-detail .box-five .hint p {
  display: none;
  right: -35px;
  background: #fff;
  top: 30px;
  padding: 0 10px;
  line-height: 28px;
  height: 28px;
  position: absolute;
  border: 1px solid #ffa200;
  white-space: nowrap;
  z-index: 1;
}
.order-detail .box-five .pr-50 {
  padding-right: 50px;
}
.order-detail .box-five ul {
  text-align: right;
  float: right;
  line-height: 28px;
  color: #666;
}
.order-detail .box-five .hint {
  display: inline-block;
  position: relative;
  top: 3px;
  margin-right: 5px;
}

</style>
