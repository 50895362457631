<template>
  <!--header area start-->
  <header class="header_area">
    <!--header top start-->
    <div class="header_top">
      <div class="hd-bd">
        <div class="reg">
          <span>您好，欢迎来到集元小样</span>
        </div>
        <div class="logout" :class="isLogin?'login':'login_no'" @click="logout"><span>|</span>退出</div>
        <div class="enter">
          <span class="er04 blue phone"><b></b> 400-830-2058</span>
          <a class="qq blue" id="forQQ" qq="4000800709"><b></b>4000800709</a>
          <div class="member-list-wrap" id="center-wrapper-btn" :class="isLogin?'login':'login_no'">
            <router-link :to="{path:'/member/center'}" class="er01 my-member">个人中心<b></b></router-link>
            <div class="member-list" style="display: none;">
              <dl>
                <dd>
                  <a class="my-order" rel="nofollow" href="#">我的订单</a>
                  <span>|</span>
                  <a class="my-collection" href="#">我的收藏</a>
                </dd>
                <dd>
                  <a class="complaints-sale" rel="nofollow" href="#">售后投诉</a>
                  <span>|</span>
                  <a class="my-discount-coupon" rel="nofollow" href="#">我的优惠券</a>
                </dd>
                <dd>
                  <a class="prepaid-balance" rel="nofollow" href="#">预付余额</a>
                  <span>|</span>
                  <a class="shipping-address" rel="nofollow" href="#">收货地址</a>
                </dd>
              </dl>
            </div>
          </div>
          <div class="reg" :class="isLogin?'login_no':'login'">
            <router-link  id="register" rel="nofollow" :to="{name:'Register'}">免费注册</router-link>
            <router-link  id="login" rel="nofollow" :to="{name:'Login'}">请登录</router-link>
          </div>
        </div>
      </div>
    </div>
    <!--header top start-->
    <!--header middel start-->
    <div class="header_middel" style="top: 0px">
      <div class="logo">
        <div class="lcsc">
          <router-link class="lcsc-pic01" :to="{name:'Home'}">
            <img
              alt="集元小样一站式电子元器件采购商城"
              src="../assets/img/logo-big.png"
              style="vertical-align: bottom; width: 220px; height: 76px"
            />
          </router-link>
        </div>
        <div class="sch">
          <div class="sch-bd">
            <form
              action="https://so.szlcsc.com/global.html"
              id="global-search-form"
              method="get">
              <input
                class="sch-bd02"
                id="search-input"
                type="text"
                value=""
                placeholder="请输入元器件型号"
                autocomplete="off"
                maxlength="53"
              />
              <button class="sch-bd03" name="" type="button" value="">
                <svg class="icon" aria-hidden="true"><use xlink:href="#icon-sousuo"></use></svg>
              </button>
            </form>
            <div
              class="search-list"
              id="search-list"
              style="display: none"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!--header middel end-->
    <!--header bottom satrt-->
    <div class="header_end">
      <div class="nav">
        <div class="nav-bd">
          <div class="sort" @mouseover="showCatalogs" @mouseleave="hideCatalogs">
            <p><svg class="icon" aria-hidden="true"><use xlink:href="#icon-mulu"></use></svg>&nbsp;全部商品分类</p>
            <!--目录-->
            <div class="layout-catalogs ass not-index-page" :class="isShowCatalogs?'index-page':'not-index-page'">
              <ul>
                <li class="ass-list" v-for="(category,index) in categoryList" :key="index">
                  <a><span class="ass-pic-312 pic"></span>{{category.name}}</a>
                  <div class="s-submnu">
                    <dl>
                      <dt>
                        <router-link
                          class="two-catalog"
                          :to="{path: '/products/' + category.id}">
                          {{category.name}}</router-link
                        >
                        <span></span>
                      </dt>
                      <dd v-for="(category,index) in category.children" :key="index">
                        <router-link
                          :to="{path: '/products/' + category.id}">
                          {{category.name}}</router-link
                        >
                      </dd>
                      <div class="clear"></div>
                    </dl>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="snav">
            <router-link  :class="($route.path==='/home')?'nav-link hover':'nav-link'"
                          :to="{name:'Home'}" keyword="首页"> 首页 </router-link>
            <router-link  :class="($route.path==='/products')?'nav-link hover':'nav-link'"
                          :to="{name:'ProductList'}" keyword="商品目录"> 商品目录 </router-link>
            <router-link  :class="($route.path==='/information')?'nav-link hover':'nav-link'"
                          :to="{name:'Information'}" keyword="资讯动态"> 资讯动态 </router-link>
          </div>
        </div>
      </div>
    </div>
    <!--header bottom end-->
  </header>
  <!--header area end-->
</template>

<script>
import { getCategoryTreeListAPI } from '@/api/apis'

export default {
  name: 'nvaHead',
  data () {
    return {
      isLogin: false,
      isShowCatalogs: false,
      categoryList: []
    }
  },
  mounted () {
    this.getCategoryList()
    this.getIsLogin()
  },
  methods: {
    logout () {
      localStorage.removeItem('token')
      localStorage.removeItem('tokenTime')
      localStorage.removeItem('memberId')
      this.$router.push({ path: '/home' })
      this.isLogin = false
    },
    getIsLogin () {
      const token = localStorage.getItem('token')
      if (token) {
        this.isLogin = true
      } else {
        this.isLogin = false
      }
    },
    showCatalogs () {
      this.isShowCatalogs = true
    },
    hideCatalogs () {
      this.isShowCatalogs = false
    },
    getCategoryList () {
      getCategoryTreeListAPI()
        .then(res => {
          this.categoryList = res.data.data
          // console.log(res.data.data)
        })
        .catch(err => console.log(err))
    }
  }
}
</script>

<style lang="less" scoped>
.header_top {
  width: 100%;
  height: 40px;
  background: #f2f2f2;
}

.header_top .hd-bd {
  position: relative;
  margin: 0 auto;
  width: 1200px;
  height: 40px;
  line-height: 40px;
}

.header_top .hd-bd .reg {
  float: left;
  margin-right: 5px;
  width: auto;
  color: #666;
  font-size: 12px;
  height: 40px
}
.login{
  display: block;
}
.login_no{
  display: none;
}
.header_top .hd-bd .enter .member-list-wrap {
  position: relative;
  float: right;
}

.header_top .hd-bd .enter a.er01.er01:hover {
  position: relative;
  z-index: 1200;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  color: #EF5121;
  background: #fff;
}

.header_top .hd-bd .enter a.er01 {
  padding: 0 6px;
  border: 1px solid transparent;
  border-top: none;
  line-height: 40px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.header_top .hd-bd .enter a {
  float: right;
  color: #666;
  margin-right: 8px;
  align-items: center;
  font: 12px / 18px "Microsoft YaHei", '微软雅黑', Arail;
}

a {
  color: #444;
  text-decoration: none;
  cursor: pointer;
}

.header_top .hd-bd .enter .member-list-wrap .member-list {
  position: absolute;
  top: 39px;
  right: 0;
  z-index: 1112;
  display: none;
  margin: 0;
  padding-bottom: 8px;
  width: 185px;
  margin-right: 8px;
  border: 1px solid #e5e5e5;
  background: #fff;
}
.header_top .hd-bd .enter .member-list-wrap .member-list dl {
  margin: 10px 0 0 15px;
  font-size: 14px;
}

.header_top .hd-bd .enter .member-list-wrap .member-list dl dd {
  float: left;
  width: 100%;
  line-height: 26px;
  margin-inline-start: 0px;
}

.header_top .hd-bd .enter .member-list-wrap .member-list dl dd a {
  float: left;
  padding-left: 0;
  color: #666;
  line-height: 26px;
  font-size: 14px;
}
.header_top .hd-bd .enter .member-list-wrap .member-list dl dd span {
  margin: 0 12px;
  float: left;
  color: #bdbdbd;
}

.header_top .hd-bd .logout {
  float: right;
  padding-left: 6px;
  cursor: pointer;
  height: 40px;
  margin-top: 11px;
  font: 12px / 18px "Microsoft YaHei", '微软雅黑', Arail;
}

.header_top .hd-bd .logout span {
  margin-right: 6px;
  color: #ccc;
}
dl {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.header_top .hd-bd .enter {
  position: relative;
  z-index: 1;
  float: right;
  color: #EF5121;
}

.header_top .hd-bd .reg a {
  margin: 0 4px;
  color: #9a9a9a;
  line-height: 40px;
}

.header_top .hd-bd .enter a {
  float: right;
  color: #666;
}

.header_top .hd-bd .reg #register {
  font-weight: 700;
  color: #666;
}

.header_top .hd-bd .reg #login {
  font-weight: 700;
  color: #EF5121;
}

.header_top .hd-bd .enter span.er04 {
  float: right;
  color: #EF5121;
  font-weight: 700;
}

.header_top .hd-bd .enter span.er04 b {
  margin-right: 2px;
  display: inline-block;
  width: 18px;
  height: 18px;
  vertical-align: text-top;
  background: url(../assets/img/phone.svg) no-repeat;
  background-size:100% 100%;
}

.header_top .hd-bd .enter a.qq b {
  display: inline-block;
  width: 16px;
  height: 18px;
  background: url(../assets/img/qq.svg) no-repeat;
  background-size:100% 100%;
  vertical-align: text-bottom;
  margin-right: 4px;
}

.header_top .hd-bd .enter a.qq {
  float: right;
  width: auto;
  height: 40px;
  line-height: 40px;
  margin-right: 18px;
  color: #666;
  background-position: 0 -61px;
}

.header_middel {
    padding: 7px 0 6px;
    min-width: 1200px;
    height: 90px;
    border-top: 1px solid #dedede;
    background: #fff;
}

.logo {
    width: 1200px;
    margin: 0 auto;
}

.logo .lcsc {
    float: left;
    height: 90px;
}

.logo .lcsc a {
    margin-top: 10px;
    float: left;
    margin-right: 62px;
}

.logo .sch {
    position: relative;
    float: right;
    padding-top: 24px;
    width: 45%;
    height: 70px;
}

.sch-bd {
    width: 100%;
    float: left;
    position: relative;
}

.sch-bd .sch-bd02 {
    float: left;
    width: 80%;
    height: 38px;
    line-height: 38px;
    text-indent: 8px;
    border: 2px solid #EF5121;
    border-radius: 3px 0 0 3px;
    outline: none;
}

.sch-bd .sch-bd03 {
    width: 53px;
    height: 44px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    border: 2px solid #EF5121;
    border-radius: 0 3px 3px 0;
    background-color: #EF5121;
}

button, input[type=button] {
    cursor: pointer;
    outline: none;
}

.header_end .nav {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #EF5121;
  background: #fff;
}
.header_end .nav .nav-bd {
  margin: 0 auto;
  width: 1200px;
}
.header_end .nav .nav-bd .sort {
  float: left;
  width: 20%;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  cursor: pointer;
}

.header_end .nav .nav-bd .sort p {
  line-height: 50px;
  text-indent: 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin-top: 0px;
  margin-bottom: 0px;
  background: #EF5121;
}

.header_end .nav .nav-bd .sort p span {
  display: inline-block;
  width: 20px;
  height: 18px;
  vertical-align: middle;
}

.layout-catalogs.not-index-page {
  display: none;
}

.layout-catalogs.index-page {
  display: block;
}

.layout-catalogs {
  position: relative;
  z-index: 1111;
  float: left;
  width: 240px;
  height: max-content;
  line-height: 35px;
  box-sizing: border-box;
  font-size: 12px;
  background: #f35c2f;
}

.layout-catalogs ul {
  box-sizing: border-box;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0px;
}

li, ul {
  list-style-type: none;
}

ul {
  margin: 0px;
}

.layout-catalogs ul li {
  width: 100%;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
}

.layout-catalogs ul li:hover {
  background: #fff;
  cursor: pointer;
}

.layout-catalogs ul li>a {
  height: 38px;
  font-size: 14px;
  padding-left: 40px;
}

.layout-catalogs ul li a {
  float: left;
  display: block;
  border: 1px solid transparent;
  color: #fff;
}

.layout-catalogs ul li:hover a {
  color: #EF5121;
}

.layout-catalogs ul li:hover .s-submnu {
  display: block;
}

.layout-catalogs ul li .s-submnu {
  display: none;
  position: absolute;
  z-index: 98;
  left: 239px;
  padding: 0 20px;
  padding-bottom: 12px;
  width: 564px;
  border: 1px solid #dedede;
  border-left: none;
  background: #fff;
  box-shadow: 9px 8px 16px rgb(0 0 0 / 18%);
}

.layout-catalogs ul li .s-submnu dl dt {
  margin-top: 8px;
  margin-left: 0px;
  padding: 0 20px;
  line-height: 44px;
  border-bottom: 1px solid #e3e3e3;
  font-weight: 700;
  color: #EF5121;
}

.layout-catalogs ul li .s-submnu dl dt span {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url(../assets/img/arrows.svg) no-repeat;
}

.layout-catalogs ul li .s-submnu dl dt .two-catalog {
  float: none;
  display: inline;
  color: #EF5121;
}

.layout-catalogs ul li .s-submnu dl dd {
  float: left;
  margin-top: 14px;
  margin-left: 0px;
  padding: 0 20px;
  line-height: 12px;
  border-right: 1px solid #adadad;
  color: #494949;
}

dd {
  display: block;
  margin-inline-start: 40px;
}

.layout-catalogs ul li .s-submnu dl dd a:hover {
  color: #EF5121;
}

.layout-catalogs ul li .s-submnu dl dd a {
  font-weight: 400;
  color: #666;
}

.clear {
  clear: both;
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
}

a {
  color: #444;
  text-decoration: none;
  cursor: pointer;
}

.header_end .nav .nav-bd .snav {
  float: left;
  padding-left: 42px;
  font-size: 16px;
  font-weight: 700;
}
.header_end .nav .nav-bd .snav a {
  position: relative;
  float: left;
  margin-right: 42px;
  display: block;
  text-align: center;
  line-height: 50px;
  text-decoration: none;
  color: #444;
}
.header_end .nav .nav-bd .snav a.hover,
.header_end .nav .nav-bd .snav a:hover {
  display: block;
  color: #EF5121;
}
</style>
