<template>
<div class="product">
    <NavHead />
    <div class="product_content">
        <div class="search-datas">
                    <div class="data-article">
                        <div class="header clear">
                            <div class="boxsiz fl common-td common-td-140">型号</div>
                            <div class="boxsiz fl common-td common-td-100">供应商</div>
                            <div class="boxsiz fl common-td common-td-100">库存</div>
                            <div class="boxsiz fl common-td common-td-120">起订量/倍数</div>
                            <div class="boxsiz fl common-td common-td-100">数量阶梯</div>
                            <div class="boxsiz fl common-td common-td-120">香港交货</div>
                            <div class="boxsiz fl common-td common-td-120">大陆交货(含税)</div>
                            <div class="boxsiz fl common-td common-td-140">货期</div>
                            <div class="boxsiz fl common-td common-td-110 common-td-center" style="width: 250px;">操作</div>
                        </div>
                      <li class="body-div clear" v-for="item in 4" :key="item">
                                <!-- <div class="body clear"> -->
                                    <div class="boxsiz fl common-td common-td-140 model-td">
                                        <strong class="model">
                                          <router-link  :to="{name:'Product'}" target="_blank" title="矩形连接器触头0430300001采购价格">0430300001</router-link>
                                        </strong>
                                        <p class="classify">矩形连接器触头</p>
                                        <div class="down-div lb-box">
                                            <div class="icon-div  line-block">
                                                <i class="el-icon-circle-plus-outline"></i>
                                                <div class="img-div-absolute boxsiz">
                                                    <img src="https://oss.icdeal.com/www/973605a33fa94ef9bb99bcc9693e9cb1.JPG" alt="_">
                                                </div>
                                            </div>
                                            <a href="https://www.molex.com/pdm_docs/ps/PS-43650.pdf" class="line-block va-m" rel="nofollow" target="_blank">数据手册下载</a>
                                        </div>
                                    </div>
                                    <div class="boxsiz fl common-td common-td-100 zzs-td">
                                        <a href="https://www.icdeal.com/supplier/2/" target="_blank" title="TI Store代理元器件采购"><b>TI Store</b></a>
                                    </div>
                                    <div class="boxsiz fl common-td common-td-100 kc-td">
                                        <p>1293770</p>
                                    </div>
                                    <div class="boxsiz fl common-td common-td-120 qdl-td">
                                        <p class="qdl-p ellipsis">起订量: <span>1</span></p>
                                        <p class="bs-p ellipsis">倍数:
                                            <span>1</span>
                                        </p>
                                    </div>
                                    <div class="fl step-price">
                                        <div class="step-price-height" style="max-height: 202px;">
                                            <div class="ladder-price clear">
                                                <p class="num-td  common-td common-td-100 ellipsis boxsiz fl"><span>1</span>+</p>
                                                <p class="xg-td  common-td common-td-120 ellipsis boxsiz fl">$ <span>0.1</span></p>
                                                <p class="dl-td  common-td common-td-120 ellipsis boxsiz fl">￥<span>0.741845</span></p>
                                            </div>
                                            <div class="ladder-price clear">
                                                <p class="num-td  common-td common-td-100 ellipsis boxsiz fl"><span>10</span>+</p>
                                                <p class="xg-td  common-td common-td-120 ellipsis boxsiz fl">$ <span>0.073</span></p>
                                                <p class="dl-td  common-td common-td-120 ellipsis boxsiz fl">￥<span>0.541547</span></p>
                                            </div>
                                            <div class="ladder-price clear">
                                                <p class="num-td  common-td common-td-100 ellipsis boxsiz fl"><span>100</span>+</p>
                                                <p class="xg-td  common-td common-td-120 ellipsis boxsiz fl">$ <span>0.052</span></p>
                                                <p class="dl-td  common-td common-td-120 ellipsis boxsiz fl">￥<span>0.385759</span></p>
                                            </div>
                                            <div class="ladder-price clear">
                                                <p class="num-td  common-td common-td-100 ellipsis boxsiz fl"><span>1000</span>+</p>
                                                <p class="xg-td  common-td common-td-120 ellipsis boxsiz fl">$ <span>0.04368</span></p>
                                                <p class="dl-td  common-td common-td-120 ellipsis boxsiz fl">￥<span>0.324038</span></p>
                                            </div>
                                            <div class="ladder-price clear">
                                                <p class="num-td  common-td common-td-100 ellipsis boxsiz fl"><span>2500</span>+</p>
                                                <p class="xg-td  common-td common-td-120 ellipsis boxsiz fl">$ <span>0.03952</span></p>
                                                <p class="dl-td  common-td common-td-120 ellipsis boxsiz fl">￥<span>0.293177</span></p>
                                            </div>
                                            <div class="ladder-price clear">
                                                <p class="num-td  common-td common-td-100 ellipsis boxsiz fl"><span>12000</span>+</p>
                                                <p class="xg-td  common-td common-td-120 ellipsis boxsiz fl">$ <span>0.03136</span></p>
                                                <p class="dl-td  common-td common-td-120 ellipsis boxsiz fl">￥<span>0.232643</span></p>
                                            </div>
                                            <div class="ladder-price clear">
                                                <p class="num-td  common-td common-td-100 ellipsis boxsiz fl"><span>24000</span>+</p>
                                                <p class="xg-td  common-td common-td-120 ellipsis boxsiz fl">$ <span>0.02842</span></p>
                                                <p class="dl-td  common-td common-td-120 ellipsis boxsiz fl">￥<span>0.210832</span></p>
                                            </div>
                                            <div class="ladder-price clear">
                                                <p class="num-td  common-td common-td-100 ellipsis boxsiz fl"><span>60000</span>+</p>
                                                <p class="xg-td  common-td common-td-120 ellipsis boxsiz fl">$ <span>0.02646</span></p>
                                                <p class="dl-td  common-td common-td-120 ellipsis boxsiz fl">￥<span>0.196292</span></p>
                                            </div>
                                        </div>
                                        <div class="step-price-btn  common-btn common-btn-radius common-btn-white-border no-show-price">
                                            更多梯度价格
                                            <i class="iconfont iconarrow_page_down_default"></i>
                                        </div>

                                    </div>
                                    <div class="boxsiz fl common-td common-td-140 hq-td clear">
                                        <div class="check-div lb-box  active dl-check">
                                            <i class="iconfont iconselect_multiple_y line-block va-m"></i><span class="line-block va-m">大陆：8-15工作日</span>
                                        </div>
                                        <div class="check-div lb-box  xg-check">
                                            <i class="iconfont iconselect_multiple_n line-block va-m"></i><span class="line-block va-m">香港：7-12工作日</span>
                                        </div>
                                        </div>

                                        <div class="boxsiz fl common-td common-td-110 cz-td common-td-center" style="width: 250px;">
                                            <router-link :to="{name:'Login'}"  class="btn common-btn common-btn-orange now-ask-btn  common-btn-radius icp-ask-btn" rel="nofollow">立即购买</router-link>
                                            <router-link :to="{name:'Login'}"  class="btn common-btn common-btn-green now-ask-btn  common-btn-radius icp-ask-btn" rel="nofollow">立即询价</router-link>
                                        </div>
                                    <!-- </div> -->
                            </li>
                    </div>
            </div>
    </div>
    <NavSfot />
    <NavFoot />
</div>
</template>

<script>
import NavHead from '@/components/NavHead.vue'
import NavFoot from '@/components/NavFoot.vue'
import NavSfot from '@/components/NavSfot.vue'

export default {
  name: 'ProductList',
  components: {
    NavHead,
    NavFoot,
    NavSfot
  }
}
</script>

<style lang="less" scoped>
.product{
  width: 100%;
}
.product_content {
    width: 1200px;
    margin: 0 auto;
}
.search-datas {
    padding-top: 10px;
    background-color: #fff;
    margin-top: 12px;
}
.search-datas .data-article {
    color: #333;
}
.search-datas .data-article .header {
    background-color: #F5F7FA;
    height: 36px;
}
.clear {
    zoom: 1;
}
.search-datas  .data-article .common-td.common-td-140 {
    width: 140px;
}
.search-datas  .data-article .common-td {
    padding: 10px 0 10px 10px;
}

.boxsiz {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.fl {
    float: left;
}
.search-datas .data-article .common-td.common-td-100 {
    width: 100px;
}
.search-datas .data-article .common-td.common-td-120 {
    width: 120px;
}
.search-datas .data-article .common-td.common-td-110 {
    width: 110px;
}

ol, ul, dl {
    list-style: none;
}
ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
li {
    display: list-item;
    list-style-type:none;
    text-align: -webkit-match-parent;
}
em, b, i {
    font-style: normal;
}
p{
    margin-top: 0px;
    margin-bottom: 0px;
}
.search-datas .data-article .body-div {
    min-height: 110px;
    padding-bottom: 10px;
}
.search-datas .data-article .common-td.model-td .classify {
    line-height: 24px;
    color: #333;
}
.search-datas .data-article .common-td.model-td .model {
    display: block;
}
.search-datas .data-article .common-td.model-td .model a {
    display: block;
    max-height: 48px;
    line-height: 24px;
    overflow: hidden;
    color: #333;
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
a {
    text-decoration: none;
}
.search-datas .data-article .common-td.model-td .classify {
    line-height: 24px;
    color: #333;
}
.search-datas  .data-article .common-td.model-td .down-div {
    line-height: 24px;
}
.lb-box {
    font-size: 0 !important;
}
.search-datas .data-article .common-td.model-td .down-div .icon-div {
    margin-right: 5px;
    position: relative;
}
.line-block {
    display: inline-block;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
}
.search-datas .data-article .common-td.model-td .down-div .icon-div i {
    font-size: 14px;
    color: #28292B;
    cursor: pointer;
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.search-datas .data-article .common-td.model-td .down-div .icon-div .img-div-absolute {
    position: absolute;
    width: 0px;
    height: 0px;
    padding: 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 10%);
    bottom: -127px;
    right: -130px;
    z-index: 3;
}
.search-datas .data-article .common-td.model-td .down-div .icon-div .img-div-absolute img {
    height: 0;
    width: 0;
}
.search-datas .data-article .common-td.model-td .down-div .icon-div:hover .img-div-absolute {
    width: 124px;
    height: 124px;
    padding: 6px;
}
.search-datas .data-article .common-td.model-td .down-div .icon-div:hover .img-div-absolute img {
    height: 112px;
    width: 112px;
}

.search-datas .data-article .common-td.model-td .down-div a {
    color: #1072CC;
    font-size: 12px;
}
.search-datas .data-article .common-td.zzs-td p,
.search-datas .data-article .common-td.zzs-td a {
    display: block;
    line-height: 16px;
    color: #333;
    max-height: 32px;
    overflow: hidden;
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 4px 0 10px 0;
}

.search-datas .data-article .common-td.kc-td p,
.search-datas .data-article .common-td.qdl-td p {
    word-wrap: break-word;
    word-break: break-all;
    line-height: 24px;
}
.search-datas .data-article .step-price {
    width: 340px;
    min-height: 1px;
}
.search-datas .data-article .step-price .step-price-height {
    max-height: 106px;
    overflow: hidden;
}
.search-datas .data-article .step-price .step-price-height .ladder-price:first-child {
    color: #FF5A06;
}
.search-datas .data-article .step-price .step-price-height .ladder-price:first-child p {
    padding-bottom: 0px;
    padding-top: 10px;
    font-weight: 600;
}
* {
    margin: 0;
    padding: 0;
    font-weight: 400;
}
.search-datas .data-article .step-price .step-price-height .ladder-price p {
    padding-top: 0;
    padding-bottom: 0;
}
.search-datas .data-article .common-td.num-td,
.search-datas  .data-article .common-td.dl-td,
.search-datas  .data-article .common-td.xg-td {
    line-height: 24px;
}
.search-datas .data-article .step-price .step-price-btn:hover {
    background-color: #09C867;
    border: 1px solid #09C867;
    color: #fff;
}

.search-datas .data-article .step-price .step-price-btn {
    width: 160px;
    height: 26px;
    line-height: 24px;
    margin: 14px 20px 0 0;
    font-size: 12px;
    border: 1px solid #666;
    color: #333;
    border-radius: 0;
}
.clear:after {
    content: '.';
    clear: both;
    height: 0;
    overflow: hidden;
    display: block;
    visibility: hidden;
}
.search-datas .data-article .common-td.common-td-center {
    text-align: center;
}
.search-datas .data-article .common-td.cz-td .btn:first-child {
    margin-top: 0;
}

.search-datas .data-article .common-td.cz-td .btn.now-ask-btn {
    line-height: 30px;
}
.search-datas .data-article .common-td.cz-td .btn {
    display: block;
    width: 80px;
    height: 30px;
    margin: 10px auto 0;
    line-height: 28px;
    font-size: 12px;
}

.common-btn.common-btn-green {
    background-color: #09C867;
}

.common-btn.common-btn-orange {
  background-color: #e57925;
}
.common-btn.common-btn-radius {
    border-radius: 4px;
}
.common-btn {
    text-align: center;
    box-sizing: border-box;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}
.search-datas .data-article .common-td.hq-td .check-div {
    cursor: pointer;
    line-height: 24px;
}
.search-datas .data-article .common-td.hq-td .check-div span {
    font-size: 12px;
    margin-left: 8px;
}
.search-datas .data-article .common-td.hq-td .check-div.active i {
    color: #3D7FFF;
}

.search-datas .data-article .common-td.hq-td .check-div i {
    font-size: 14px;
}
.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
