<template>
  <div class="login">
    <div id="header">
        <div class="header-content">
          <div class="logo transparent-logo fl">
            <router-link :to="{name:'Home'}" title="集元小样电子元器件商城">
              <img src="../assets/img/logo-big.png" alt="集元小样电子元器件商城"
                   style="vertical-align: bottom; width: 220px; height: 76px"/>
            </router-link>
          </div>
          <div class="fr header-contact call-us">
            <img src="../assets/img/qq.svg" style="width: 21px;height: 21px;">
            <b>在线咨询</b>
          </div>
        </div>
      </div>
    <div class="header-line"></div>
    <div class="has-banner-content">
        <div class="swiper-container banner-swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide">
            </div>
          </div>
        </div>
        <form onsubmit="return false;" class="login-form">
          <div class="login-box fr">
            <div class="title-nav clear">
              <div class="normal-login-nav login-nav">
                <div class="fl cur">密码登录</div>
              </div>
            </div>
            <!-- 普通登陆 -->
            <div class="normal-login">
              <div class="input-content clear">
                <div class="fl password-login">
                  <div class="input-box">
                    <input
                      type="text"
                      class="phone-input long-input borderRed"
                      placeholder="请输入手机号码"
                      id="pwdPhontInput"
                      autocomplete="on"
                      v-model="phone"
                    />
                  </div>
                  <div class="input-box">
                    <input
                      type="password"
                      class="password-input long-input"
                      placeholder="请输入密码"
                      autocomplete="on"
                      v-model="password"
                    />
                  </div>
                </div>
              </div>
              <div class="btn-content clear">
                <div class="login-btn cur" @click="postLogin">登录</div>
              </div>
              <div class="other-content clear">
                <div class="fr">
<!--                  <a href="#" class="forgot-btn fl" rel="nofollow">忘了密码</a>-->
<!--                  <span class="line fl"></span>-->
                  <router-link tag="a" class="forgot-btn fl" target='_blank' style="color:#f55d22" rel="nofollow"
                               :to="{name:'Register'}">现在注册
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    <NavFoot/>
  </div>
</template>

<script>
import NavFoot from '@/components/NavFoot.vue'
import { postLoginAPI } from '@/api/apis.js'
export default {
  name: 'Login',
  components: {
    NavFoot
  },
  data () {
    return {
      phone: '',
      password: ''
    }
  },
  methods: {
    postLogin () {
      if (this.phone.length < 11) {
        this.$message({
          message: '请输入手机号码！',
          type: 'warning'
        })
        return
      }
      if (this.password.length < 6) {
        this.$message({
          message: '请输入密码！',
          type: 'warning'
        })
        return
      }
      postLoginAPI({ telephone: this.phone, password: this.password })
        .then(res => {
          localStorage.setItem('token', res.data.data.token)
          const nowTime = new Date().getTime()
          localStorage.setItem('tokenTime', nowTime)
          localStorage.setItem('phone', this.phone)
          localStorage.setItem('memberId', res.data.data.memberId)
          this.$message({
            message: '登录成功！',
            type: 'success'
          })
          let curr = localStorage.getItem('preRoute')
          // 判断一下如果直接进入登录页，无上一页面 默认跳转首页
          if (curr === '/' || curr === '/register') {
            curr = '/home'
          }
          this.$router.push({ path: curr })
        })
        .catch(err => console.log(err))
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

.has-banner-content{
  flex: 1;
}

#header {
  background: transparent;
  height: 110px;
  width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.header-content {
  margin: 0 auto;
  padding: 0 30px;
}

.logo {
  margin-top: 15px;
}

.fl {
  float: left;
}

.logo a {
  display: block;
}

img {
  border: none;
}

.header-contact {
  line-height: 110px;
  color: #000;
  padding-left: 25px;
  cursor: pointer;
}

.fr {
  float: right;
}

.header-line {
  background: #000;
  width: 100%;
  height: 1px;
  bottom: 0;
  opacity: 0.2;
}

.has-banner-content {
  position: relative;
}

.swiper-container {
  position: relative;
}

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  direction: ltr;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  -webkit-transition-property: -webkit-transform, left, top;
  -webkit-transition-duration: 0s;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition-timing-function: ease;
  -moz-transition-property: -moz-transform, left, top;
  -moz-transition-duration: 0s;
  -moz-transform: translate3d(0, 0, 0);
  -moz-transition-timing-function: ease;
  -o-transition-property: -o-transform, left, top;
  -o-transition-duration: 0s;
  -o-transform: translate3d(0, 0, 0);
  -o-transition-timing-function: ease;
  -o-transform: translate(0, 0);
  -ms-transition-property: -ms-transform, left, top;
  -ms-transition-duration: 0s;
  -ms-transform: translate3d(0, 0, 0);
  -ms-transition-timing-function: ease;
  transition-property: transform, left, top;
  transition-duration: 0s;
  transform: translate3d(0, 0, 0);
  transition-timing-function: ease;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-wrapper {
  width: 100%;
  height: 680px;
  overflow: hidden;
}

.swiper-slide {
  float: left;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 100%;
  width: 100%;
  background: url(../assets/img/bg-login.png) no-repeat center;
}

.activity-link {
  display: block;
  width: 730px;
  height: 560px;
  position: relative;
  top: 100px;
  left: 50%;
  margin-left: -560px;
}

.has-banner-content .login-form {
  width: 1200px;
  margin: 0 auto;
  top: -610px;
  position: relative;
  z-index: 10;
}

.has-banner-content .login-box {
  position: absolute;
  right: 0;
  top: 50px;
}

.login-box {
  height: 440px;
  width: 340px;
  background: #fff;
  padding: 20px 30px;
  position: relative;
}

.title-nav div {
  font-size: 18px;
  color: #aaa;
  cursor: pointer;
}

.normal-login-nav div.cur {
  border-bottom: 2px solid #333;
}

.title-nav {
  overflow: hidden;
  line-height: 50px;
  border-bottom: 1px solid #eee;
}

.title-nav div.cur {
  color: #333;
}

.normal-login-nav div {
  margin-right: 30px;
}

.title-nav div {
  font-size: 18px;
  color: #aaa;
  cursor: pointer;
}

.normal-login {
  position: relative;
}

.input-content {
  margin-top: 15px;
  position: relative;
  overflow: hidden;
  height: 130px;
}

.clear {
  clear: both;
}

.phone-login {
  left: 340px;
}

.password-login {
  position: absolute;
  top: 0;
}

.input-box {
  margin-top: 20px;
  overflow: hidden;
}

input.long-input {
  width: 328px;
}

input {
  vertical-align: middle;
  margin: 0;
  padding: 0;
  outline: 0;
}

.input-content input {
  height: 43px;
  padding: 0;
  line-height: 15px;
  font-size: 14px;
  padding-left: 10px;
  line-height: 43px \9;
  border: 1px solid #e0e0e0;
}

.login-btn.cur {
  background: #f55d22;
  color: #fff;
  cursor: pointer;
}

.login-btn {
  margin-top: 20px;
  width: 340px;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  color: #aaa;
  background: #eee;
  text-align: center;
  cursor: not-allowed;
}

.other-content {
  margin-top: 15px;
  color: #333;
  font-size: 14px;
  overflow: hidden;
}

.forgot-btn {
  color: #999;
  font-size: 14px;
}

.other-content span.line {
  height: 14px;
  margin: 0 10px;
  margin-top: 3px;
}

span.line {
  float: left;
  margin-top: 7px;
}

span.line {
  height: 20px;
  border-left: 1px solid #e0e0e0;
  float: left;
}
</style>
