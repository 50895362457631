import Vue from 'vue'
import { Button, Carousel, CarouselItem, Icon, Image, Dialog, Form, FormItem, Option, Input, Cascader, Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Image)
Vue.use(Icon)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Option)
Vue.use(Input)
Vue.use(Cascader)

Vue.component(Message)
Vue.prototype.$message = Message
