<template>
<div class="member">
  <NavHead />
  <div class="main-container">
    <div class="content-container page-member-center">
      <div class="main-content-wrap mt16">
        <!--侧边导航-->
        <div class="page-member-sidebar" id="page-member-sidebar" issettleaccount="false">
          <h3 class="active">
            <router-link :to="{path: '/member/center'}">会员中心</router-link>
          </h3>
          <dl>
            <dt>个人中心</dt>
<!--            <dd>-->
<!--              <a href="#" buriedpointop="Edit_account_information">账户信息</a>-->
<!--            </dd>-->
            <dd>
              <router-link :to="{name:'MemberAddress'}" buriedpointop="manage_the_shipping_address">收货地址</router-link>
            </dd>
<!--            <dd>-->
<!--              <a href="#" buriedpointop="change_login_password" target="_blank">更改密码</a>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <a href="#" buriedpointop="VAT_invoice_information">开票资料</a>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <a href="#" buriedpointop="account_binding">账号绑定</a>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <a href="#" buriedpointop="secure_setting">安全设置</a>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <a href="#" buriedpointop="my_material_code">我的物料编码</a>-->
<!--            </dd>-->

            <dt>交易管理</dt>
            <dd>
              <router-link :to="{name: 'MemberOrder'}" buriedpointop="order_management">订单管理</router-link>
            </dd>

<!--            <dd>-->
<!--              <a href="#" buriedpointop="paid_order">代付订单</a>-->
<!--            </dd>-->

<!--            <dd>-->
<!--              <a href="#" buriedpointop="BOM_manage">BOM管理</a>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <a href="#" buriedpointop="save_amount">节省金额</a>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <a href="#" buriedpointop="purchase_history">购买历史</a>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <a href="#" buriedpointop="electronic_statement">电子对账单</a>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <a href="#" buriedpointop="before_enquiry">星级询价</a>-->
<!--            </dd>-->

<!--            <dt>资产中心</dt>-->
<!--            <dd>-->
<!--              <a href="#" buriedpointop="prepaid_balance_account">预付余额</a>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <a href="#" buriedpointop="usd_prepaid_balance_account">美元预付余额</a>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <a href="#">我的采购金</a>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <a href="#" buriedpointop="my_discount_coupon">我的优惠券</a>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <a href="#" buriedpointop="my_points_details">我的积分</a>-->
<!--            </dd>-->
          </dl>
        </div>
        <router-view/>
      </div>
    </div>
  </div>
  <NavSfot />
  <NavFoot />
</div>
</template>

<script>
import NavHead from '@/components/NavHead'
import NavFoot from '@/components/NavFoot'
import NavSfot from '@/components/NavSfot'

export default {
  name: 'Member',
  components: {
    NavHead,
    NavFoot,
    NavSfot
  }
}
</script>

<style lang="less" scoped>
.member{
  width: 100%;
}
.main-container {
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}
.content-container {
  margin: 0 auto;
  width: 1200px;
}
.mt16 {
  margin-top: 16px;
}
.page-member-sidebar {
  float: left;
  padding-bottom: 10px;
  width: 17%;
  border: 1px solid #e5e5e5;
}
.page-member-sidebar h3 {
  padding-top: 15px;
  padding-left: 22px;
}
.page-member-sidebar h3 a {
  font-size: 16px;
}
a {
  color: #444;
  text-decoration: none;
  cursor: pointer;
}
dl {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.page-member-sidebar dl dt {
  margin: 10px 12px 0 22px;
  padding: 16px 0 12px;
  border-top: 1px dashed #e5e5e5;
  font-weight: 700;
  font-size: 15px;
}
.page-member-sidebar dl dd {
  padding: 0 12px 0 25px;
  line-height: 30px;
}
.page-member-sidebar dl a {
  font-size: 14px;
}
</style>
