<template>
  <div class="info-list">
    <NavHead/>
    <div class="info_content"><p>敬请期待</p></div>
    <NavSfot/>
    <NavFoot/>
  </div>
</template>

<script>
// @ is an alias to /src
import NavHead from '@/components/NavHead.vue'
import NavFoot from '@/components/NavFoot.vue'
import NavSfot from '@/components/NavSfot.vue'

export default {
  name: 'Information',
  components: {
    NavHead,
    NavFoot,
    NavSfot
  }
}

</script>
<style lang="less" scoped>
.info-list{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  min-height: 100vh;
}
.info_content{
  flex: 1;
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  /*实现水平居中*/
  justify-content: center;
}

</style>
