<template>
  <div class="member_address">
    <div class="member-content-right">
      <div class="member-content-right-title">
        <h3>
          收货地址
          <div class="edit-address">
            <span class="add-address" @click="addAddress"><svg class="icon" aria-hidden="true"><use xlink:href="#icon-plus"></use></svg>新增</span>
          </div>
        </h3>
      </div>
      <table class="address-b" width="100%" border="0" cellspacing="0" cellpadding="0">
        <tbody><tr class="table-head">
          <td class="percent20">收件人</td>
          <td class="percent30">收货地址</td>
          <td class="percent20">联系手机</td>
          <td class="percent20">操作</td>
        </tr>

<!--        <tr>-->
<!--          <td>kk</td>-->
<!--          <td>北京市-北京市-西城区-kkk123456</td>-->
<!--          <td>18796821592</td>-->
<!--          <td class="operate">-->
<!--              <span>-->
<!--                  <a class="is-default"><b></b>默认地址</a>-->
<!--              </span>-->
<!--            <span class="td-edit"><a>修改</a></span>-->

<!--          </td>-->
<!--        </tr>-->

        <tr v-for="(item,index) in addresss" :key="index">
          <td>{{item.name}}</td>
          <td>{{item.province}}-{{item.city}}-{{item.region}}-{{item.detailAddress}}</td>
          <td>{{item.phoneNumber}}</td>
          <td class="operate">
<!--              <span>-->
<!--                  <a class="setDefault" data-uuid="AA03E53B4CA41DD43B8923EBB69A5C63">设为默认</a>-->
<!--              </span>-->
            <span class="td-edit" @click="updateAddress(item)"><a>修改</a></span>

            <span class="td-delete" @click="delAddress(item.id,index)"><a>删除</a></span>

          </td>
        </tr>

        </tbody></table>
      <el-dialog class="dialog-center" title="新增/修改收货地址" width="35%" :visible.sync="dialogFormVisible">
        <el-form :model="address">
          <el-form-item label="* 收货人：" :label-width="formLabelWidth">
            <el-input v-model="address.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="* 手机：" :label-width="formLabelWidth">
            <el-input v-model="address.phoneNumber" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="* 所在地区：" :label-width="formLabelWidth">
            <el-cascader
              size="large"
              clearable
              :options="options"
              v-model="selectedOptions"
              @change="addressChange">
            </el-cascader>
          </el-form-item>
          <el-form-item label="* 详细地址：" :label-width="formLabelWidth">
            <el-input v-model="address.detailAddress" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="postAddress">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
import { delAddressAPI, getAddressListAPI, postAddAddressAPI, updateAddressAPI } from '@/api/apis'
export default {
  name: 'MemberAddress',
  data () {
    return {
      formLabelWidth: '120px',
      dialogFormVisible: false,
      selectedOptions: [],
      address: {
        defaultStatus: 0,
        detailAddress: '',
        id: 0,
        memberId: 0,
        name: '',
        phoneNumber: '',
        province: '',
        city: '',
        region: ''
      },
      upAddress: {},
      options: regionData,
      addresss: []
    }
  },
  mounted: function () {
    this.address.memberId = localStorage.getItem('memberId')
    this.getAddressList()
  },
  methods: {
    addressChange (arr) {
      console.log(arr)
      console.log(CodeToText[arr[0]], CodeToText[arr[1]], CodeToText[arr[2]])
      this.address.province = CodeToText[arr[0]]
      this.address.city = CodeToText[arr[1]]
      this.address.region = CodeToText[arr[2]]
    },
    getAddressList () {
      getAddressListAPI().then(res => {
        console.log(res)
        this.addresss = res.data.data
      }).catch(err => console.log(err))
    },
    addAddress () {
      this.address = {
        defaultStatus: 0,
        detailAddress: '',
        id: 0,
        memberId: 0,
        name: '',
        phoneNumber: '',
        province: '',
        city: '',
        region: ''
      }
      this.selectedOptions = []
      this.dialogFormVisible = true
    },
    postAddress () {
      if (this.address.name.length < 2) {
        this.$message({
          message: '请输收件人！',
          type: 'warning'
        })
        return
      }
      if (this.address.phoneNumber.length < 9) {
        this.$message({
          message: '请输入联系号码！',
          type: 'warning'
        })
        return
      }
      if (this.address.province.length < 2) {
        this.$message({
          message: '请选择所在地区！',
          type: 'warning'
        })
        return
      }
      if (this.address.detailAddress.length < 5) {
        this.$message({
          message: '请输入详细地址！',
          type: 'warning'
        })
        return
      }
      this.dialogFormVisible = false
      if (this.address.id === 0) {
        postAddAddressAPI(this.address)
          .then(res => {
            this.$message({
              message: '添加成功！',
              type: 'success'
            })
            console.log(res)
            this.addresss.push(this.address)
          }).catch(err => console.log(err))
      } else {
        updateAddressAPI(this.address)
          .then(res => {
            this.$message({
              message: '修改成功！',
              type: 'success'
            })
            console.log(res)
            this.addresss.splice(this.addresss.findIndex(item => item === this.upAddress), 1)
            this.addresss.push(this.address)
          }).catch(err => console.log(err))
      }
    },
    delAddress (id, index) {
      delAddressAPI(id)
        .then(res => {
          this.$message({
            message: '删除成功！',
            type: 'success'
          })
          console.log(res)
          this.addresss.splice(index, 1)
          this.selectedOptions = []
        }).catch(err => console.log(err))
    },
    updateAddress (address) {
      this.dialogFormVisible = true
      this.upAddress = address
      this.address = this.deepCopy(address)
      if (address.province) {
        this.selectedOptions = [
          TextToCode[address.province].code,
          TextToCode[address.province][address.city].code,
          TextToCode[address.province][address.city][address.region].code
        ]
      }
    },
    getClass (o) { // 判断数据类型
      return Object.prototype.toString.call(o).slice(8, -1)
    },
    deepCopy (obj) {
      let result
      const oClass = this.getClass(obj)
      if (oClass === 'Object') result = {} // 判断传入的如果是对象，继续遍历
      else if (oClass === 'Array') result = [] // 判断传入的如果是数组，继续遍历
      else return obj // 如果是基本数据类型就直接返回
      for (var i in obj) {
        var copy = obj[i]
        if (this.getClass(copy) === 'Object') result[i] = this.deepCopy(copy) // 递归方法 ，如果对象继续变量obj[i],下一级还是对象，就obj[i][i]
        else if (this.getClass(copy) === 'Array') result[i] = this.deepCopy(copy) // 递归方法 ，如果对象继续数组obj[i],下一级还是数组，就obj[i][i]
        else result[i] = copy // 基本数据类型则赋值给属性
      }
      return result
    }
  }
}
</script>

<style>
a {
  color: #444;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: #EF5121;
}
h3 {
  font-size:  14px
}

.member-content-right {
  float: left;
  margin-left: 1.1%;
  width: 81.5%;
}
.member-content-right-title {
  position: relative;
}

.member-content-right .member-content-right-title {
  padding-left: 20px;
  line-height: 45px;
  border: 1px solid #e5e5e5;
  color: #444;
  background: #efedee;
}
.edit-address {
  position: absolute;
  top: 15px;
  right: 16px;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  font-weight: 100;
}
.edit-address .add-address {
  display: inline-block;
  cursor: pointer;
}

.edit-address span b {
  margin-right: 4px;
  display: inline-block;
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}

table {
  width: 100%;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  -webkit-border-horizontal-spacing: 0px;
  -webkit-border-vertical-spacing: 0px;
}

.address-b {
  margin-top: 15px;
  text-align: center;
  border-top: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.address-b .table-head {
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: #f8f8f8;
}
.address-b tr td {
  padding: 5px 10px;
  height: 38px;
  font-size: 12px;
  border-bottom: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
}
.address-b .table-head td{
  font-weight: 700;
  font-size: 14px;
}

.percent10 {
  width: 10%!important;
}
.percent20 {
  width: 20%!important;
}
.percent30 {
  width: 30%!important;
}

.address-b .operate span {
  margin-right: 10px;
}
.address-b .operate .is-default b {
  display: inline-block;
  width: 14px;
  height: 16px;
  background: url(https://static.szlcsc.com/ecp/public/img/order_icon.a6d725f4.png) no-repeat -254px -312px;
}

.el-icon-close:hover{color:#EF5121 !important}

.el-input.is-active .el-input__inner, .el-input__inner:focus {
  border-color: #EF5121;
  outline: 0;
}
.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner {
  border-color:#EF5121;
}
.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path {
  color: #EF5121;
  font-weight: 700;
}
.dialog-footer .el-button--primary {
  color: #fff;
  background-color: #EF5121;
  border-color: #EF5121;
}

.dialog-footer .el-button--primary:focus,.dialog-footer .el-button--primary:hover {
  background: #f53c04;
  border-color: #f53c04;
  color: #fff;
}

.dialog-footer .el-button:active {
  color: #EF5121;
  border-color: #EF5121;
  outline: none;
}

.dialog-footer .el-button:focus, .el-button:hover {
  color: #fff;
  border-color: #ea9379;
  background-color: #eca894;
}
</style>
