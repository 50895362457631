<template>
  <div class="order_pay">
    <NavHeadTop/>
    <div class="order_pay_content">
    <header id="header">
      <div class="w1200 clear head-text head-textx2">
        <div class="logo-nav logo-nav2x">
          <a href="/" class="logo"><img alt="收银台" src="../assets/img/logo-big.png"></a>
          <span class="string"></span>

          <h2 class="fw">收银台</h2>
        </div>
      </div>
    </header>
    <div class="w1200 clear checkstand">
      <div class="title">
        <p class="price"><span class="fl-r">应付金额：<b class="f-red payMoney">¥1266.60</b></span><span class="f-33"><span class="zylyst">订单审核通过，请尽快付款！</span>订单号: <span class="sn">12021042838251</span></span><span class="daojishi "></span></p>
        <div class="intro">
          <p class="intro-but"><a href="javascript:void('0')" class="f-blue">订单详情<i class="icon-arrows icon-arrows-top"></i></a></p>
          <div class="block display-no" style="display: block;">
            <i class="arrows"></i>
            <div class="clear">
              <div class="spleft fl-l flbox">
                <div class="clear">
                  <div class="fl-l pcbboxtext">商品<span class="znamey">型号</span>及数量：</div>
                  <div class="fl-l nameShow"><p>AP8266ATCC-R1 *3000</p><p>CC0805KRX7R9BB154 *5</p></div>
                </div>
              </div>
              <div class="inforight fl-l flbox">
                <p><span class="f-gray">下单时间：</span> <span class="creat-time">2021-04-28 13:51:50</span></p>
                <p><span class="f-gray">收件信息：</span> <span class="adressp">11 18575572592 北京东城区北京111</span></p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="payment">
        <h4>选择支付方式</h4>
        <ul class="clear payBox">
          <!--            <li class="fl-l" guid="1" style="display: list-item;">-->
          <!--              <img src="https://static.ichunt.com/dist/res/home/images/weixinpay.png" alt="收银台">-->
          <!--              微信支付-->
          <!--              <i></i>-->
          <!--            </li>-->
          <li class="fl-l act" guid="2" style="display: list-item;">
            <img src="https://static.ichunt.com/dist/res/home/images/applaypay.png" alt="收银台">
            支付宝
            <i></i>
          </li>
        </ul>
        <a class="payButton" target="_blank" data-href="/v3/pay/apply?type=0&amp;id=2706687">
          立即支付
        </a>
      </div>
    </div>
    </div>
    <NavSfot/>
    <NavFoot/>
  </div>
</template>

<script>
import NavHeadTop from '@/components/NavHeadTop'
import NavFoot from '@/components/NavFoot'
import NavSfot from '@/components/NavSfot'

export default {
  name: 'OrderPay',
  components: {
    NavHeadTop,
    NavFoot,
    NavSfot
  }
}
</script>

<style scoped>
.order_pay{
  width: 100%;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  min-height: 100vh;
}
.order_pay_content{
  flex: 1;
}
#header {
  height: 106px;
  min-width: 1200px;
  background: #ffffff;
}
.head-text {
  height: 76px;
  padding: 15px 0;
}

.w1200 {
  margin: 0 auto;
  max-width: 1200px;
  min-width: 1200px;
}
.clear, .clr {
  display: block;
  min-height: 1%;
}
.head-text .logo-nav {
  float: left;
  width: 387px;
  margin-right: 40px;
}
.head-text .logo-nav .logo {
  float: left;
  display: block;
}
.head-text .logo-nav img {
  display: block;
  margin: 0 auto;
  float: left;
  width: 220px;
  height: 76px;
}
.head-text .logo-nav .string {
  float: left;
  overflow: hidden;
  display: inline-block;
  margin: 15px 20px;
  width: 1px;
  height: 40px;
  background-color: #d4d4d4;
}
.head-text .logo-nav h2 {
  font-weight: bold;
  float: left;
  font-size: 16px;
  height: 76px;
  line-height: 76px;
  font-family: "Microsoft YaHei";
  color: #333;
}

.checkstand {
  background: #fff;
  margin: 20px auto;
}
.checkstand .title {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}
.checkstand .title .price {
  font-size: 14px;
  color: #333;
  line-height: 30px;
  padding-bottom: 10px;
}
.checkstand .title .price .fl-r {
  font-size: 12px;
  color: #666;
}
.fl-r, .fr {
  float: right;
}
.checkstand .title .price b {
  font-size: 16px;
  font-weight: bold;
}

.f-red {
  color: #ff0000!important;
}
.checkstand .title .intro-but {
  text-align: right;
}
.f-blue {
  color: #EF5121;
  font-size: 14px;
}
.checkstand .title .block {
  display: block;
  padding: 10px 20px;
  line-height: 21px;
  margin-top: 10px;
  border: 1px solid #e5e5e5;
  background: #fff;
  position: relative;
}

.clear, .clr {
  display: block;
  min-height: 1%;
}
.intro .flbox {
  width: 50%;
  font-size: 12px;
}
.fl-l, .fl {
  float: left;
}
.nameShow p {
  width: 430px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.clear:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.checkstand .payment {
  padding: 10px 20px;
}
.checkstand .payment h4 {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  height: 30px;
  line-height: 30px;
  padding: 10px 0;
}
.payBox li.act {
  border-color: #EF5121;
}
.payBox li {
  display: none;
  cursor: pointer;
  width: 216px;
  height: 70px;
  border: 1px solid #e5e5e5;
  margin-right: 15px;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  color: #333;
  font-weight: bold;
  font-size: 14px;
  line-height: 70px;
  position: relative;
}
.payBox li img {
  vertical-align: middle;
  margin-left: 20px;
  margin-right: 20px;
  height: 45px;
  width: 45px;
}
.payButton {
  display: block;
  background: #ff0000;
  border-radius: 4px;
  width: 120px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #fff!important;
  font-size: 18px;
  margin: 40px auto;
  cursor: pointer;
}
</style>
