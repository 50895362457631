<template>
  <div class="member_center">
    <div class="member-content-right">
      <div class="custom_c">
        <ul>
          <li>
            <a href="#">
              <div class="custom_c_1"><svg class="icon icon_svg" aria-hidden="true"><use xlink:href="#icon-gouwuche"></use></svg></div>
              购物车<b>0</b>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="custom_c_2"><svg class="icon icon_svg" aria-hidden="true"><use xlink:href="#icon-daizhifu"></use></svg></div>
              待支付<b>0</b>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="custom_c_3"><svg class="icon icon_svg" aria-hidden="true"><use xlink:href="#icon-fahuo"></use></svg></div>
              待发货<b>0</b>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="custom_c_4">
                <svg class="icon icon_svg" aria-hidden="true"><use xlink:href="#icon-shouhuo"></use></svg>
              </div>
              待收货<b>0</b>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="custom_c_5"><svg class="icon icon_svg" aria-hidden="true"><use xlink:href="#icon-tuihuozhong"></use></svg></div>
              退货中<b>0</b>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="custom_c_6"><svg class="icon icon_svg" aria-hidden="true"><use xlink:href="#icon-shoucang"></use></svg></div>
              已收藏<b>0</b>
            </a>
          </li>
        </ul>
      </div>
      <div class="clear"></div>
      <!-- 为您精选 -->
      <div class="product-similar-goods" style="display: block;">
        <div class="similar-goods-title">
          <span class="common-style title">我的订单</span>
          <div class="btn-page" style="display: block;">
            <span>查看全部订单</span>
          </div>
        </div>
        <div class="similar-goods-display">

        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  name: 'MemberCenter'
}
</script>

<style lang="less" scoped>
.member-content-right {
  float: left;
  margin-left: 1.1%;
  width: 81.5%;
}
.page-member-center .custom_c {
  height: 80px;
  border: 1px solid #e5e5e5;
  line-height: 42px;
  font-size: 14px;
}
.page-member-center .custom_c ul {
  width: 100%;
  text-align: center;
}

.page-member-center .custom_c ul li {
  float: left;
  width: 16.6666%;
  padding-top: 15px;
}
.page-member-center .custom_c ul li a {
  text-decoration: none;
}
.page-member-center .custom_c ul li a b {
  cursor: pointer;
  margin-left: 6px;
  color: #EF5121;
  font-weight: 100;
}

.page-member-center .product-similar-goods {
  height: 527px;
  background: #fff;
  border: 1px solid #f0f0f0;
  margin: 15px auto 0;
}
.page-member-center .product-similar-goods .similar-goods-title {
  width: 100%;
  padding: 20px 0 0 20px;
  position: relative;
}
.page-member-center .product-similar-goods .similar-goods-title .title {
  float: left;
  font-size: 14px;
  font-weight: 700;
  color: #444;
}
.page-member-center .product-similar-goods .similar-goods-title .btn-page {
  position: absolute;
  right: 40px;
  top: 21px;
  font-size: 14px;
  color: #999;
}
.icon_svg {
  width: 30px;
  height: 30px;
}
.page-member-center .custom_c_1, .page-member-center .custom_c_2, .page-member-center .custom_c_3, .page-member-center .custom_c_4, .page-member-center .custom_c_5, .page-member-center .custom_c_6 {
  height: 24px;
  width: 24px;
  position: relative;
  left: 64px;
}
</style>
