<template>
  <div class="spot">
    <ul>
      <li class="sp01">
        <div>
          <span>多</span>
          <h4>海量现货</h4>
          <p>40万现货SKU</p>
          <p>品类不断扩充</p>
        </div>
      </li>
      <li class="sp02">
        <div>
          <span>快</span>
          <h4>闪电发货</h4>
          <p>科技智能大仓储</p>
          <p>4小时快速交货</p>
        </div>
      </li>
      <li class="sp03">
        <div>
          <span>好</span>
          <h4>严控渠道</h4>
          <p>仅从原厂和代理商进货</p>
          <p>每一颗料均可原厂追溯</p>
        </div>
      </li>
      <li class="sp04">
        <div>
          <span>省</span>
          <h4>降低成本</h4>
          <p>明码标价节省时间成本</p>
          <p>一站式采购正品元器件</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.spot {
    display: block;
    margin-top: 25px;
    background: #e3e3e3;
    width: 100%;
    height: 205px;
}
.spot ul {
    margin: 0 auto;
    height: 156px;
    background: #e3e3e3;
    width: 1198px;
    border-bottom: 1px solid #e3e3e3;
}
li, ul {
    list-style-type: none;
}
ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}
.spot ul li {
    width: 24%;
    float: left;
    padding-top: 48px;
    text-align: center;
}
.spot ul li.sp01 div {
    padding-left: 42px;
}
.spot ul li div {
    width: 165px;
    margin: 0 auto 10px;
    padding-left: 58px;
    text-align: left;
}

.spot ul li div span {
    float: left;
    margin-top: 10px;
    width: 50px;
    height: 50px;
    display: block;
    background: #EF5121;
    border-radius: 50%;
    font-size: 25px;
    color: #fff;
    text-align: center;
    line-height: 46px;
}
.spot ul li div h4 {
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: 100;
    margin-left: 56px;
}

.spot ul li div p {
    font-size: 12px;
    color: #9a9a9a;
    margin-bottom: 4px;
    margin-left: 56px;
    word-break: keep-all;
}
.spot ul li div p {
    font-size: 12px;
    color: #9a9a9a;
    margin-bottom: 4px;
    margin-left: 56px;
    word-break: keep-all;
}
</style>
