<template>
  <div class="product_detai">
    <NavHead />
    <div class="content">
      <div class="bread-nav clear">
        <div class="bread-nav-content">
          <a href="/catalog" class="fl first-a">全部分类</a>
          <a href="/category3-1" class="fl">连接器/传感器</a>
          <a href="/category22-2" class="fl">连接器</a>
          <a href="/category112-3" class="fl">排针排母</a>
          <a href="" class="fl last-a">A2541WV-6P</a>
        </div>
      </div>
       <div class="wrapper main" id="main">
        <div class="main-content">
          <div class="main-info">
            <div class="box fl">
              <div class="tb-booth tb-pic tb-s310">
                <a>
                  <img
                    src="https://img.allchips.com/online/product/brand/2281/A2541WV-XP.jpg"
                    alt=""
                    rel="https://img.allchips.com/online/product/brand/2281/A2541WV-XP.jpg"
                    class="jqzoom"
                    style="cursor: crosshair"
                  />
                </a>
              </div>
              <div class="img-nav">
                <ul class="tb-thumb fl" id="thumblist">
                  <li class="tb-selected">
                    <div class="tb-pic tb-s40">
                      <a>
                        <img
                          src="https://img.allchips.com/online/product/brand/2281/A2541WV-XP.jpg"
                          mid="https://img.allchips.com/online/product/brand/2281/A2541WV-XP.jpg"
                          big="https://img.allchips.com/online/product/brand/2281/A2541WV-XP.jpg"
                          alt="product"
                          onerror="imgError(this);"
                        />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>

              <p class="img-tip clear">注：图像仅供参考，请参阅产品规格</p>
            </div>
            <div
              class="fl info-box"
              data-storeid="101140"
              data-storename="硬之城自营"
              data-wareid="41569763"
              data-vc="D390"
            >
              <div class="info-title clearfix">
                <span
                  class="fl partnum"
                  data-warenum="D390-A2541WV-6P-2281-A-0000-Q-N"
                >
                  <span class="fl">A2541WV-6P</span>
                </span>

              </div>
              <div class="desc-info">
                <div class="desc-item clear desc-type-1">
                  <div class="desc-title fl">参数描述</div>
                  <div class="desc-content fl">
                    针座连接器 0.100" (2.54mm) 杜邦2.54排针直插 镀金 6位
                  </div>
                </div>
                <div class="desc-item clear desc-type-1">
                  <div class="desc-title fl">供应商</div>
                  <div class="desc-content fl">硬之城自营</div>
                </div>
                <div class="desc-type-2">
                  <div class="desc-item fl">
                    <div class="desc-title fl">供应商型号</div>
                    <div class="desc-content fl">--</div>
                  </div>
                  <div class="desc-item fl brand-item">
                    <div class="desc-title fl">品牌</div>
                    <div class="desc-content fl brand-info" data-brandid="2281">
                      CJT(长江)
                    </div>
                  </div>
                </div>
                <div class="desc-type-2">
                  <div class="desc-item fl">
                    <div class="desc-title fl">商品类别</div>
                    <div class="desc-content fl">排针排母</div>
                  </div>
                  <div class="desc-item fl">
                    <div class="desc-title fl">数据手册</div>
                    <div class="desc-content fl">
                      <a
                        href="http://www.cjt.com/upload/A2541.pdf"
                        id="docLnk"
                        target="_blank"
                      >
                        <span class="fl" style="text-decoration: underline"
                          >数据手册</span
                        >
                        <span class="pdf-icon fl"></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="desc-type-2">
                  <div class="desc-item fl">
                    <div class="desc-title fl">批次</div>
                    <div class="desc-content fl batchNumber-info">--</div>
                  </div>
                  <div class="desc-item fl">
                    <div class="desc-title fl">最小包</div>
                    <div class="desc-content fl mpq-info">1000</div>
                  </div>
                </div>
                <div class="desc-type-2">
                  <div class="desc-item fl">
                    <div class="desc-title fl">库存</div>
                    <div class="desc-content fl stock-info" data-stocktype="20">
                      期货
                    </div>
                  </div>
                  <div class="desc-item fl">
                    <div class="desc-title fl">起订量</div>
                    <div class="desc-content fl moq-info" style="width: 100px">
                      1000
                    </div>
                  </div>
                  <div class="desc-item fl">
                    <div class="desc-title fl">递增量</div>
                    <div
                      class="desc-content fl"
                      style="width: 100px"
                      id="multiple"
                      data-multiple="1000"
                    >
                      1000
                    </div>
                  </div>
                </div>
              </div>
              <div class="info-right">
                <div class="price-title price-cell">
                  <div class="fl amount-cell">价格梯度</div>

                  <div class="fl cn-cell">
                    <span class="cur-price">大陆(含税)</span>
                  </div>

                  <div class="fl hk-cell">
                    <span class="cur-price">香港交货</span>
                  </div>
                </div>
                <div
                  class="price-content"
                  data-pricelist='[{"wareId":41569763,"minAmount":1000,"priceCN":37170,"originalPriceCN":null,"priceHK":4838,"originalPriceHK":null,"marketPriceCN":0,"marketPriceHK":null,"thirdOriginalPriceCN":null,"thirdOriginalPriceHK":null},{"wareId":41569763,"minAmount":5000,"priceCN":35910,"originalPriceCN":null,"priceHK":4674,"originalPriceHK":null,"marketPriceCN":0,"marketPriceHK":null,"thirdOriginalPriceCN":null,"thirdOriginalPriceHK":null},{"wareId":41569763,"minAmount":10000,"priceCN":34650,"originalPriceCN":null,"priceHK":4510,"originalPriceHK":null,"marketPriceCN":0,"marketPriceHK":null,"thirdOriginalPriceCN":null,"thirdOriginalPriceHK":null}]'
                >
                  <div class="price-item price-cell cur-price-cn">
                    <div class="fl amount-cell">1000+</div>

                    <div class="fl cn-cell">
                      <span class="cur-price" title="￥0.3717">￥0.3717</span>
                    </div>

                    <div class="fl hk-cell">
                      <span class="cur-price" title="$0.04838">$0.04838</span>
                    </div>
                  </div>

                  <div class="price-item price-cell">
                    <div class="fl amount-cell">5000+</div>

                    <div class="fl cn-cell">
                      <span class="cur-price" title="￥0.3591">￥0.3591</span>
                    </div>

                    <div class="fl hk-cell">
                      <span class="cur-price" title="$0.04674">$0.04674</span>
                    </div>
                  </div>

                  <div class="price-item price-cell">
                    <div class="fl amount-cell">10000+</div>

                    <div class="fl cn-cell">
                      <span class="cur-price" title="￥0.3465">￥0.3465</span>
                    </div>

                    <div class="fl hk-cell">
                      <span class="cur-price" title="$0.0451">$0.0451</span>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="price-item price-cell">
                    <div class="fl amount-cell" v-html="'&nbsp;'"></div>

                    <div
                      class="fl deliveryCn"
                      data-min="2"
                      data-max="3"
                      data-unit="20"
                    >
                      交期：2-3周
                    </div>

                    <div
                      class="fl deliveryHk"
                      data-min="2"
                      data-max="3"
                      data-unit="20"
                    >
                      交期：2-3周
                    </div>
                  </div>
                </div>
              </div>
              <div class="info-left">
                <div class="num-box info-item clear">
                  <div class="clear">
                    <div class="desc-title fl">采购数量</div>
                    <div class="desc-content fl">
                      <input type="text" class="fl acount" value="1000" />
                      <div class="fl">
                        <div class="add-btn num-btn"></div>
                        <div class="reduce-btn num-btn"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="info-item clear">
                  <div class="desc-title fl" style="line-height: 32px">
                    交货地
                  </div>
                  <div class="desc-content fl">
                    <div class="fl place-select place-selected" data-v="CN">
                      大陆
                    </div>

                    <div class="fl place-select" data-v="HK">香港</div>
                  </div>
                </div>
                <div class="info-item clear price-item">
                  <div class="desc-title fl">合计</div>
                  <div class="desc-content fl">
                    <span class="total-price">￥371.70</span>
                  </div>
                </div>
              </div>
              <div class="clear btn-box">
                <router-link :to="{name:'Order'}" class="buy-btn">立即购买</router-link>
                <router-link :to="{name:'Login'}" class="cart-btn">加入购物车</router-link>
              </div>
            </div>
          </div>
          <div id="wareDetailMain" class="ware-detail clearfix ware-detail-s">
            <div class="fl" id="wareIntro">
              <div class="detail-inner" id="detailInner">
                <div class="ware-detail-title" id="wareDetailNav">
                  <ul class="ware-detail-nav clearfix">
                    <li class="ware-detail-nav-item">
                      <i class="icon-s icon-list_icon_details_default"></i
                      >商品详情
                    </li>
                    <li id="navDoc" class="last ware-detail-nav-item cur">
                      <i class="icon-s icon-list_icon_pdf"></i>数据手册
                    </li>
                  </ul>
                </div>
                <div class="jsWareDetailCon ware-detail-box hide">
                  <table class="property-tblist">
                    <tbody>
                      <tr class="row-odd">
                        <th data-desc="器件的外观尺寸描述">
                          尺寸/封装<i class="iconfont icon-shuomingxiala"></i>
                        </th>
                        <td>Through Hole,P=2.54mm</td>
                      </tr>
                      <tr class="">
                        <th data-desc="电镀是在另一金属上沉积另一层金属的过程">
                          触点电镀<i class="iconfont icon-shuomingxiala"></i>
                        </th>
                        <td>黄铜</td>
                      </tr>
                      <tr class="row-odd">
                        <th data-desc="按连接器的用途、安装方法等来描述的词">
                          连接器类型<i class="iconfont icon-shuomingxiala"></i>
                        </th>
                        <td>直，180度，排针</td>
                      </tr>
                      <tr class="">
                        <th data-desc="器件的引脚数量">
                          针脚数<i class="iconfont icon-shuomingxiala"></i>
                        </th>
                        <td>6</td>
                      </tr>
                      <tr class="row-odd">
                        <th
                          data-desc="指表面组装元器件相邻引脚中心线之间的距离"
                        >
                          脚间距<i class="iconfont icon-shuomingxiala"></i>
                        </th>
                        <td>2.54mm</td>
                      </tr>
                      <tr class="">
                        <th data-desc="排数">
                          排数<i class="iconfont icon-shuomingxiala"></i>
                        </th>
                        <td>1</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div id="wareDetailDoc" class="jsWareDetailCon ware-detail-doc">
                  <ul class="doc-list">
                    <li>
                      <div class="doc-list-inner">
                        <i class="icon-s icon-pdf_list"></i>
                        <div class="doc-list-con">
                          <p>
                            <a
                              href="http://www.cjt.com/upload/A2541.pdf"
                              target="_blank"
                              >A2541WV-6P<br />
                              数据手册Datasheet PDF</a
                            >
                          </p>
                          <p class="ft">
                            <a
                              href="http://www.cjt.com/upload/A2541.pdf"
                              target="_blank"
                              >下载</a
                            >
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- 国产替代料 -->
              </div>
              <div class="detail-inner dtl-hp">
                <div class="ware-detail-title">
                  <h2>常见问题</h2>
                </div>
                <div class="dtl-hp-con">
                  <dl>
                    <dt>1.平台上看到的库存、价格准确吗？</dt>
                    <dd>
                      答：相对准确的，但也考虑到现货库存流动性比较大，目前还无法做到100%的精准。可以即时在线下单，如有异常，我们的客户经理也会及时联系你们。
                    </dd>
                  </dl>
                  <dl>
                    <dt>2.可以提供原厂或代理的资质证明吗？</dt>
                    <dd>
                      答：目前我们自营代理的品牌有近60个，其他渠道均为原厂及代理渠道，这些入驻平台时我们都严格审核过的，如有需要，我们可以针对具体品牌型号来沟通确认。
                    </dd>
                  </dl>
                  <dl>
                    <dt>3.你们怎么保证是原厂或代理？</dt>
                    <dd>
                      答：我们有专门的商务合作部门，对原厂和代理的资质进行严格的审核和认证，供应商上架物料前必须通过资质审核；我们比任何一位客户都注重产品的渠道和质量，对于供应商的审核是非常严格的，您可以放心选择！
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      4.硬之城平台价格普遍较低，但为什么有某些料价格偏高？
                    </dt>
                    <dd>
                      答：我们平台品质保障是第一位的！平台上所有产品都是原厂或代理直供的，虽然不能保证每颗料都做到价格最低，但是在质量上，我们有绝对的保障，另外样品和批量的价格也是有差异的。如您有具体的型号目标价格，也可以发我们帮您申请下价格。
                    </dd>
                  </dl>
                  <dl>
                    <dt>5.线上拍单很麻烦，可以走你们线下或代下单吗？</dt>
                    <dd>
                      答：我们可提供代下单服务，您只需在客户端登陆后，点击“我的订单”进入交易管理中即可见“导入明细”界面,核对无误后全选点击“下单”。另外，在线下单可享受等值现金的京东卡或其他精选的礼品。
                    </dd>
                  </dl>
                  <dl>
                    <dt>6.你们平台上的下单流程是什么样的？</dt>
                    <dd>
                      <ul class="dtl-hp-lc clearfix">
                        <li class="first">搜索物料</li>
                        <li class="spec">加入购物车/直接购买</li>
                        <li class="spec1">确认地址和发票资料</li>
                        <li class="last">去支付</li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="undercarriage hide"></div>
        <div class="embargo-icon hide"></div>
        <span class="cart-ball" id="fly"></span>
      </div>
    </div>
    <NavSfot />
    <NavFoot />
  </div>
</template>

<script>
import NavHead from '@/components/NavHead.vue'
import NavFoot from '@/components/NavFoot.vue'
import NavSfot from '@/components/NavSfot.vue'

export default {
  name: 'Product',
  components: {
    NavHead,
    NavFoot,
    NavSfot
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 1200px;
  margin: 0 auto;
}
.bread-nav {
    background: #fafafa;
    height: 40px;
    line-height: 40px;
}

.clear {
    clear: both;
}

div, li, ul {
    margin: 0;
    padding: 0;
}
.bread-nav a {
    background: url(https://www.allchips.com/static/site/images/shopping/result_icon_more.png) no-repeat right center;
    padding: 0 11px;
    padding-left: 5px;
    color: #333;
}

.fl {
    float: left;
}
a {
    text-decoration: none;
    color: #555;
}
#main {
    position: relative;
}

.main {
    margin: 0 auto;
    padding-top: 20px;
}
.main-content {
    width: 1200px;
    margin: 0 auto;
}
.main-info {
    padding: 20px 0 53px 0;
    display: inline-block;
}
.box {
    width: 400px;
}
.tb-booth {
    border: 1px solid #f2f2f2;
    position: relative;
    z-index: 1;
}
.tb-s310, .tb-s310 img {
    max-height: 400px;
    max-width: 400px;
}
.tb-s310,
.tb-s310 a {
    height: 400px;
    width: 400px;
}
.img-nav {
    clear: both;
    margin-top: 20px;
    overflow: hidden;
    height: 55px;
}
.tb-thumb {
    overflow: hidden;
    overflow-x: auto;
    width: 330px;
}
.tb-thumb li.tb-selected {
    border: 1px solid #f55d22;
}
.tb-thumb li:last-child {
    margin-right: 0;
}
.tb-thumb li {
    float: left;
    height: 53px;
    width: 53px;
    margin: 0 11px 0 0;
    overflow: hidden;
    border: 1px solid #999;
}
.tb-thumb .tb-selected div {
    background-color: #fff;
    border: medium none;
}
.tb-s40, .tb-s40 a {
    height: 55px;
    width: 55px;
}
.img-tip {
    font-size: 12px;
    color: #999;
    margin-top: 10px;
}
.tb-pic a img {
    vertical-align: middle;
}
.tb-pic a {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.info-box {
    margin-left: 20px;
    width: 780px;
}
.info-box .info-title {
    font-size: 20px;
    color: #333;
    margin-bottom: 15px;
}
.desc-info {
    background: #fafafa;
    padding: 10px;
    font-size: 12px;
    display: inline-block;
}
.desc-item {
    overflow: hidden;
    margin-bottom: 10px;
}
.desc-title {
    width: 73px;
    margin-right: 15px;
    color: #999;
    text-align: right;
}

.desc-content {
    color: #666;
}

.desc-type-1 .desc-content {
    width: 672px;
}

.desc-type-2 .desc-content {
    width: 274px;
}
.info-right {
    padding: 0 20px;
    border: 1px solid #eee;
    margin-top: 20px;
    color: #666;
}
.price-cell {
    line-height: 35px;
    border-bottom: 1px dashed #f1f1f1;
    clear: both;
    overflow: hidden;
}
.price-cell div.amount-cell {
    width: 140px;
    padding-left: 0;
    text-align: center;
    background: 0 0;
}

.price-cell div {
    width: 280px;
}
.amount-cell {
    color: #666;
}
.price-cell div.cn-cell, .price-cell div.deliveryCn {
    width: 250px;
    padding-left: 64px;
}
.price-cell div span {
    display: inline-block;
    width: 105px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
}

.amount-cell, .cn-cell, .hk-cell {
    font-size: 14px;
}

.price-cell div.cn-cell, .price-cell div.deliveryCn {
    width: 250px;
    padding-left: 64px;
}
.info-left {
    margin-top: 20px;
    overflow: hidden;
    width: 770px;
    padding-left: 10px;
}
.num-box {
    overflow: hidden;
}

.info-item {
    margin-bottom: 23px;
    overflow: hidden;
}
.num-box .desc-title {
    line-height: 46px;
}
.desc-content {
    color: #666;
}
.num-box input {
    line-height: 44px;
    height: 44px;
    width: 183px;
    border-right: 0;
    font-size: 22px;
}

input[type=number], input[type=password], input[type=text], textarea {
    width: 172px;
    height: 28px;
    line-height: 28px;
    padding: 0 3px;
    border: 1px solid #e0e0e0;
}
.num-box .num-btn {
    width: 20px;
    height: 23px;
    cursor: pointer;
}

.add-btn {
    background: url(https://www.allchips.com/static/site/images/shopping/number_plus.png) no-repeat center;
}
.reduce-btn {
    background: url(https://www.allchips.com/static/site/images/shopping/number_reduce.png) no-repeat center;
    margin-top: -1px;
}
.num-box input {
    line-height: 44px;
    height: 44px;
    width: 183px;
    border-right: 0;
    font-size: 22px;
}

.place-select {
    width: 98px;
    border: 1px solid #999;
    height: 30px;
    text-align: center;
    color: #999;
    margin-right: 10px;
    cursor: pointer;
    line-height: 30px;
}

.place-selected {
    border-color: #f55d22;
}
.price-item {
    margin-bottom: 0;
}
.total-price {
    font-size: 24px;
    color: #f55d22;
}

.btn-box {
    margin-left: 88px;
    padding-top: 30px;
    overflow: hidden;
}
.btn-box a.buy-btn {
    color: #fff;
    background: #f55d22;
}

.btn-box a {
    float: left;
    width: 200px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 18px;
    margin-right: 20px;
}
.btn-box a.cart-btn {
    color: #fff;
    background: #ffe5db;
    color: #f55d22;
    border: 1px solid #fbbba2;
    height: 43px;
}
.btn-box a.cart-btn:hover {
    background: #f55d22;
    color: #fff;
}

.clearfix:after {
    clear: both;
    display: block;
    content: "";
}

.clearfix::after {
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
}
.ware-detail-title {
    border: 1px solid #ddd;
    background: #f2f2f2;
    line-height: 40px;
    height: 40px;
}

div, li, ul {
    margin: 0;
    padding: 0;
}

ol, td, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.ware-detail-nav li.cur {
    background: #fff;
    color: #f55d22;
    border-top: 2px solid #f55d22;
    position: relative;
    top: -2px;
    height: 41px;
}

.ware-detail-nav li {
    width: 120px;
    color: #333;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border-right: 1px solid #ddd;
    float: left;
}

.icon-s {
    display: inline-block;
    vertical-align: middle;
}

.ware-detail-nav .icon-s {
    margin-right: 5px;
}
.icon-list_icon_details_default {
    background-image: url(https://www.allchips.com/static/site/images/sprite-s.png?v=1601197965446);
    background-position: 0 -517px;
    width: 20px;
    height: 20px;
}

i {
    display: inline-block;
}
em, i {
    font-style: normal;
}

.icon-list_icon_pdf {
    background-image: url(https://www.allchips.com/static/site/images/sprite-s.png?v=1601197965446);
    background-position: 0 -577px;
    width: 20px;
    height: 20px;
}
.ware-detail-box {
    padding: 0 0 10px;
    overflow: hidden;
    border-right: 0;
    border-bottom: 0;
    margin-top: 10px;
    border-left: 0;
    margin-bottom: 20px;
    max-height: 526px;
    overflow-y: auto;
}
.property-tblist, .replace-tblist {
    width: 100%;
    border-collapse: collapse;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
}
tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.property-tblist td {
    text-align: left;
}

.property-tblist td, .property-tblist th {
    border: 1px solid #d4d4d4;
    padding: 2px;
    background: #fff;
    height: 30px;
    padding-left: 10px;
}
td, th {
    text-align: left;
}

th {
    font-weight: 400;
}
.ware-detail-doc {
    margin-bottom: 20px;
}
.doc-list li:hover {
    background-color: #f5eeeb;
}
.doc-list li:last-child .doc-list-inner {
    border-bottom: none;
}

.doc-list-inner {
    margin: 0 15px;
    border-bottom: 1px solid #eee;
    padding: 15px 0;
}
.icon-pdf_list {
    background-image: url(https://www.allchips.com/static/site/images/sprite-s.png?v=1601197965446);
    background-position: 0 -843px;
    width: 30px;
    height: 30px;
}
.doc-list-con {
    margin-left: 40px;
    margin-top: -30px;
}
.doc-list-con p {
    line-height: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.doc-list-con .ft, .doc-list-con .ft a {
    color: #999;
}

.doc-list-con .ft {
    margin-top: 2px;
}
.dtl-hp h2 {
    margin-left: 10px;
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.dtl-hp-con {
    padding: 20px 10px;
    font-size: 14px;
    position: relative;
}
.dtl-hp-con dl {
    margin-bottom: 35px;
}
.dtl-hp-con dl dt {
    font-weight: 700;
    color: #333;
}
.dtl-hp-con dl dd {
    margin-top: 8px;
    color: #555;
    margin-left: 0px
}
.dtl-hp-lc {
    width: 100%;
    height: 55px;
    background: url(https://www.allchips.com/static/site/images/shopping/dtl-hp-lc-l.png) no-repeat;
    margin-top: 15px;
}
.dtl-hp-lc li.first {
    text-align: left;
    padding-left: 5px;
}
.dtl-hp-lc li.spec {
    width: 120px;
}
.dtl-hp-lc li.spec1 {
    width: 120px;
    margin-right: 100px;
}
.dtl-hp-lc li.last {
    margin-right: 0;
    padding-left: 5px;
}
.dtl-hp-lc li {
    float: left;
    color: #f55d22;
    text-align: center;
    margin-top: 65px;
    width: 85px;
    margin-right: 93px;
    font-size: 12px;
}

</style>
