import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import ProductList from '../views/ProductList.vue'
import Product from '../views/Product.vue'
import Information from '../views/Information.vue'
import Member from '../views/Member.vue'
import MemberCenter from '@/components/MemberCenter.vue'
import MemberAddress from '@/components/MemberAddress.vue'
import MemberOrder from '@/components/MemberOrder.vue'
import Order from '@/views/Order.vue'
import OrderPay from '@/views/OrderPay.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: ['/home']
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/products',
    name: 'ProductList',
    component: ProductList,
    meta: ['/products']
  },
  {
    path: '/product',
    name: 'Product',
    component: Product
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/information',
    name: 'Information',
    component: Information,
    meta: ['/information']
  },
  {
    path: '/member',
    name: 'Member',
    component: Member,
    children: [
      {
        // MemberCenter 会被渲染在 Member 的 <router-view> 中
        path: 'center',
        name: 'MemberCenter',
        component: MemberCenter
      },
      {
        path: '/member',
        redirect: '/member/center'
      },
      {
        path: 'address',
        name: 'MemberAddress',
        component: MemberAddress
      },
      {
        path: 'order',
        name: 'MemberOrder',
        component: MemberOrder
      }
    ]
  },
  {
    path: '/order',
    name: 'Order',
    component: Order
  },
  {
    path: '/orderPay',
    name: 'OrderPay',
    component: OrderPay
  },
  {
    path: '/',
    redirect: '/home'
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    // 保存当前路由
    localStorage.setItem('preRoute', router.currentRoute.fullPath)
  }
  next()
})

export default router
