import http from './http'

// get请求 首页内容页信息展示
export function getHomeContentAPI () {
  return http.get('/home/content')
}

// get请求 获取首页商品分类
export function getCateListAPI (params) {
  return http.get('/home/productCateList/', params)
}

// get请求 获取验证码
export function getAuthCodeAPI (params) {
  return http.get('/sso/getAuthCode', params)
}

// post请求 会员注册
export function postRegisterAPI (params) {
  return http.post('/sso/register', params)
}

// post请求 会员登录
export function postLoginAPI (params) {
  return http.post('/sso/login', params)
}

// get请求 以树形结构获取所有商品分类
export function getCategoryTreeListAPI () {
  return http.get('/product/categoryTreeList')
}

// get请求 分页获取推荐品牌
export function getRecommendListAPI (params) {
  return http.get('/brand/recommendList', params)
}

// post请求 根据提交信息生成询价订单
export function postEnquiryOrderAPI (params) {
  return http.post('/order/generateEnquiryOrder', params)
}

// get请求 显示所有收货地址
export function getAddressListAPI () {
  return http.get('/member/address/list')
}

// post请求 添加收货地址
export function postAddAddressAPI (data) {
  return http.postData('/member/address/add', data)
}

// post请求 删除收货地址
export function delAddressAPI (id) {
  return http.post('/member/address/delete/' + id)
}

// post请求 修改收货地址
export function updateAddressAPI (data) {
  return http.postData('/member/address/update/' + data.id, data)
}
